import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styles from "./Dashboard.module.css";
import Sidebar from "../common/Sidebar";
import Header from "../common/Header2";
import { FaChevronRight } from "react-icons/fa";
// import { useAuth } from "../../contexts/AuthContext";
// import protectedApi from "../../services/protectedApi";
import ProposedEndeavor from "../features/proposed_endeavor.js";

function Dashboard() {
  const [expandedSections, setExpandedSections] = useState({
    education: false,
    employment: false,
  });
  const [completedItems, setCompletedItems] = useState({});
  // const { user } = useAuth();

  const toggleSection = (section) => {
    setExpandedSections((prev) => ({
      ...prev,
      [section]: !prev[section],
    }));
  };

  // 计算每个box的进度条
  const calculateBoxProgress = (items) => {
    const totalTime = items.reduce((acc, item) => {
      if (item.isDropdown) {
        return (
          acc +
          item.subItems.reduce((subAcc, subItem) => subAcc + subItem.time, 0)
        );
      }
      return acc + (item.time || 0);
    }, 0);

    const completedTime = items.reduce((acc, item) => {
      if (item.isDropdown) {
        return (
          acc +
          item.subItems.reduce(
            (subAcc, subItem) =>
              completedItems[subItem.id] ? subAcc + subItem.time : subAcc,
            0
          )
        );
      }
      return acc + (completedItems[item.id] ? item.time : 0);
    }, 0);

    return completedTime > 0 ? Math.round((completedTime / totalTime) * 100) : 0;
  };

  // 计算总进度条
  const calculateProgress = () => {
    const totalTime = statusBoxes.reduce(
      (acc, box) =>
        acc +
        box.items.reduce((boxAcc, item) => {
          if (item.isDropdown) {
            return (
              boxAcc +
              item.subItems.reduce(
                (subAcc, subItem) => subAcc + subItem.time,
                0
              )
            );
          }
          return boxAcc + (item.time || 0);
        }, 0),
      0
    );

    const completedTime = statusBoxes.reduce(
      (acc, box) =>
        acc +
        box.items.reduce((boxAcc, item) => {
          if (item.isDropdown) {
            return (
              boxAcc +
              item.subItems.reduce(
                (subAcc, subItem) =>
                  completedItems[subItem.id] ? subAcc + subItem.time : subAcc,
                0
              )
            );
          }
          return boxAcc + (completedItems[item.id] ? item.time : 0);
        }, 0),
      0
    );

    return completedTime > 0 ? Math.round((completedTime / totalTime) * 100) : 0;
  };

  const statusBoxes = [
    {
      title: "Step 1: Create Profile",
      items: [
        {
          name: "Personal Info",
          time: "~5mins",
          link: "/personal-info",
          id: "personal-info",
        },
        {
          name: "Education",
          time: "~20mins",
          isDropdown: true,
          section: "education",
          subItems: [
            {
              name: "Education History",
              link: "/education-info",
              id: "education-history",
              time: "~15mins",
            },
            {
              name: "Education Project",
              link: "/scientific-project",
              id: "education-project",
              time: "~15mins",
            },
          ],
        },
        {
          name: "Employment",
          time: "~30mins",
          isDropdown: true,
          section: "employment",
          subItems: [
            {
              name: "Employment History",
              link: "/employment-info",
              id: "employment-history",
              time: "~15mins",
            },
            {
              name: "Employment Project",
              link: "/project-info",
              id: "employment-project",
              time: "~15mins",
            },
          ],
        },
        {
          name: "Evidence",
          time: "~15mins",
          link: "/evidence",
          id: "evidence",
        },
        {
          name: "Future Plan",
          time: "~30mins",
          link: "/future-plans",
          id: "future-plans",
        },
        {
          name: "Optional: Request for Evidence (RFE)",
          link: "/rfe",
          id: "rfe",
          time: "~5mins",
        },
      ],
    },
    {
      title: "Step 2: Select Proposed Endeavors",
      items: [
        {
          name: "Generate Proposed Endeavors",
          link: "/proposed-endeavor",
          id: "proposed-endeavor",
          time: "~25mins",
        },
      ],
    },
    {
      title: "Step 3: Create Recommendation Letters",
      items: [
        // {
        //   name: "Configure Referer",
        //   link: "/recommendations/1",
        //   id: "refererConfig",
        //   time: "~25mins",
        // },
        // {
        //   name: "2nd Recommendation..",
        //   link: "/recommendations/2",
        //   id: "recommendation-2",
        //   time: "~25mins",
        // },
        // {
        //   name: "3rd Recommendation..",
        //   link: "/recommendations/3",
        //   id: "recommendation-3",
        //   time: "~25mins",
        // },
        {
          name: "Add A Recommender",
          link: "/recommender",
          id: "recommender",
          time: "~25mins",
        },
      ],
    },
    {
      title: "Step 4: Create Petition Letter",
      items: [
        {
          name: "Generate Petition Letter",
          link: "/petition-letter",
          id: "petition-letter",
          time: "~10mins",
        },
      ],
    },
    {
      title: "RFE stage",
      items: [
        {
          name: "RFE Analysis",
          link: "/rfe-analysis",
          id: "rfe-analysis",
          time: "~10mins",
        },
        {
          name: "Generate RFE",
          link: "/rfe-response",
          id: "rfe-response",
          time: "~10mins",
        },
      ],
    },
  ];

  const resourceLinks = [
    {
      title: "What are the basic requirements for NIW?",
      link: "/resources/niw-requirements",
    },
    {
      title: "7 most common mistakes to avoid",
      link: "/resources/common-mistakes",
    },
    {
      title: "DIY v.s. attorney-assisted filing",
      link: "/resources/diy-vs-attorney",
    },
  ];

  const renderItem = (item, itemIndex) => {
    if (item.isDropdown) {
      const isExpanded = expandedSections[item.section];
      return (
        <div key={itemIndex}>
          <div
            className={`${styles.item} ${styles.dropdownHeader}`}
            onClick={() => toggleSection(item.section)}
          >
            <span className={styles.itemName}>
              {item.name}
              <span
                className={`${styles.arrow} ${
                  isExpanded ? styles.expanded : ""
                }`}
              >
                ▼
              </span>
            </span>
            {item.time && <span className={styles.itemTime}>{item.time}</span>}
          </div>
          {isExpanded && (
            <div className={styles.subItems}>
              {item.subItems.map((subItem, subIndex) => (
                <Link
                  to={subItem.link}
                  key={subIndex}
                  className={`${styles.item} ${styles.subItem}`}
                >
                  <span className={styles.itemName}>{subItem.name}</span>
                </Link>
              ))}
            </div>
          )}
        </div>
      );
    }

    return (
      <Link to={item.link} key={itemIndex} className={styles.item}>
        <span className={styles.itemName}>{item.name}</span>
        {item.time && <span className={styles.itemTime}>{item.time}</span>}
      </Link>
    );
  };

  return (
    <div className={styles.container}>
      <Header />
      <div className={styles.content}>
        <Sidebar />
        <main className={styles.main}>
          <div className={styles.dashboardHeader}>
            <div className={styles.headerTop}>
              <h1 className={styles.title}>Overview</h1>
              <div className={styles.overallProgress}>
                <div className={styles.progressInfo}>
                  <span className={styles.progressLabel}>
                    Overall Progress:{" "}
                  </span>
                  <span className={styles.progressPercentage}>
                    {calculateProgress()}%
                  </span>
                </div>
                <div className={styles.progressBarWrapper}>
                  <div className={styles.progressBar}>
                    <div
                      className={styles.progressFill}
                      style={{ width: `${calculateProgress()}%` }} // 计算总进度条
                    >
                      <div className={styles.progressGlow}></div>
                    </div>
                    <div className={styles.progressSteps}>
                      {[0, 25, 50, 75, 100].map((step) => (
                        <div
                          key={step}
                          className={`${styles.progressStep} ${
                            calculateProgress() >= step ? styles.completed : ""
                          }`}
                        >
                          <div className={styles.stepDot}></div>
                          <span className={styles.stepLabel}>{step}%</span>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={styles.statusBoxes}>
            {statusBoxes.map((box, index) => (
              <div key={index} className={styles.statusBox}>
                <div className={styles.boxHeader}>
                  <h2>{box.title}</h2>
                  <div className={styles.progressBar}>
                    <div className={styles.progressText}>
                      {calculateBoxProgress(box.items)}%
                    </div>
                    <div
                      className={styles.progressFill}
                      style={{ width: `${calculateBoxProgress(box.items)}%` }} // 计算每个box的进度条
                    />
                  </div>
                </div>
                <div className={styles.itemList}>
                  {box.items.map((item, itemIndex) =>
                    renderItem(item, itemIndex)
                  )}
                </div>
              </div>
            ))}
          </div>

          <div className={styles.resourcesSection}>
            <h2 className={styles.resourcesTitle}>Tips and Resources</h2>
            <div className={styles.resourcesGrid}>
              {resourceLinks.map((resource, index) => (
                <Link
                  key={index}
                  to={resource.link}
                  className={styles.resourceCard}
                >
                  <span>{resource.title}</span>
                  <FaChevronRight className={styles.resourceArrow} />
                </Link>
              ))}
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default Dashboard;
