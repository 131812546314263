export const INDUSTRIES = [
  "Agriculture, Forestry, Fishing and Hunting",
  "Mining, Quarrying, and Oil and Gas Extraction",
  "Utilities",
  "Construction",
  "Manufacturing",
  "Wholesale Trade",
  "Retail Trade",
  "Transportation and Warehousing",
  "Information Technology",
  "Finance and Insurance",
  "Real Estate and Rental and Leasing",
  "Professional, Scientific, and Technical Services",
  "Management of Companies and Enterprises",
  "Administrative and Support Services",
  "Educational Services",
  "Health Care and Social Assistance",
  "Arts, Entertainment, and Recreation",
  "Accommodation and Food Services",
  "Public Administration",
  "Other Services",
];

export const COUNTRIES = [
  "Afghanistan",
  "Albania",
  "Algeria",
  "Andorra",
  "Angola",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bhutan",
  "Bolivia",
  "Bosnia and Herzegovina",
  "Botswana",
  "Brazil",
  "Brunei",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cabo Verde",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Central African Republic",
  "Chad",
  "Chile",
  "China",
  "Colombia",
  "Comoros",
  "Congo",
  "Costa Rica",
  "Croatia",
  "Cuba",
  "Cyprus",
  "Czech Republic",
  "Democratic Republic of the Congo",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Eswatini",
  "Ethiopia",
  "Fiji",
  "Finland",
  "France",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Greece",
  "Grenada",
  "Guatemala",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Honduras",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran",
  "Iraq",
  "Ireland",
  "Israel",
  "Italy",
  "Ivory Coast",
  "Jamaica",
  "Japan",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Kuwait",
  "Kyrgyzstan",
  "Laos",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
];
export const ORGANIZATION_TYPES = [
  "For-Profit Companies",
  "International Organizations",
  "NGO",
  "Government Agencies",
  "Academic Organizations",
  "Other Non-Profit",
];
export const EMPLOYMENT_TYPES = [
  "Full-Time",
  "Part-Time",
  "Temporary",
  "Internship",
  "Other",
];
export const LEARNING_TYPE = ["Full-Time", "Part-Time"];

export const DEGREES = [
  "Associate's Degree",
  "Bachelor's Degree",
  "Master's Degree",
  "Doctoral Degree",
  "Professional Degree",
  "High School Diploma",
  "Certificate",
  "Other",
];
export const EVIDENCE_CATEGORIES = {
  Academic: ["Course Certificate", "Degree", "Academic Award"],
  Professional: ["Certification", "License", "Training Certificate"],
  Achievement: ["Award", "Recognition", "Competition"],
  Project: ["Research", "Development", "Innovation"],
};

export const MAIN_CATEGORIES = Object.keys(EVIDENCE_CATEGORIES);
export const STATUS_OPTIONS = ["Active", "Inactive", "No-expiration Date"];
export const IMPACT_LEVEL_OPTIONS = ["International", "National", "Regional"];
export const RESEARCH_DOMAINS = [
  "Computer Science",
  "Engineering",
  "Mathematics",
  "Physics",
];
export const ENDORSEMENT_GOALS = [
  {
    id: "personal_abilities",
    label: "Endorse Personal Abilities",
    value: "personal_abilities",
  },
  {
    id: "work_impact",
    label: "Endorse Work Impact",
    value: "work_impact",
  },
  {
    id: "national_interest",
    label:
      "Endorse Evidence related to the national interest and substantial merits of the proposed endeavors",
    value: "national_interest",
  },
];
