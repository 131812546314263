// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EmailVerification_verificationSuccess__b3Wn7 {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f5f7f9;
    padding: 20px;
    font-family: sans-serif;

  }
  
  .EmailVerification_card__\\+wXBP {
    background: white;
    border-radius: 20px;
    padding: 40px;
    text-align: center;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.08);
    max-width: 500px;
    width: 90%;
    font-family: sans-serif;

  }
  
  .EmailVerification_logo__oXThl {
    width: 185px;
    margin-bottom: 30px;
  }
  
  .EmailVerification_iconContainer__I5RXN {
    margin: 30px 0;
    color: #4CAF50;
    font-size: 64px;
  }
  
  .EmailVerification_heading__Tnxlc {
    font-size: 32px;
    font-weight: 600;
    color: #2c3e50;
    margin-bottom: 20px;
    white-space: nowrap;
    /* font-family: sans-serif; */
  }
  
  .EmailVerification_message__gzm\\+p {
    font-size: 16px;
    line-height: 1.6;
    color: #546e7a;
    margin-bottom: 30px;
    font-family: sans-serif;
  }
  
  .EmailVerification_loginButton__kkyVT {
    display: inline-block;
    background: #3c5a99;
    color: white;
    padding: 15px 30px;
    border-radius: 8px;
    text-decoration: none;
    font-weight: 500;
    transition: background-color 0.2s;
    font-family: sans-serif;
  }
  
  .EmailVerification_loginButton__kkyVT:hover {
    background: #2e497a;
  }`, "",{"version":3,"sources":["webpack://./src/components/EmailVerification.module.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,yBAAyB;IACzB,aAAa;IACb,uBAAuB;;EAEzB;;EAEA;IACE,iBAAiB;IACjB,mBAAmB;IACnB,aAAa;IACb,kBAAkB;IAClB,2CAA2C;IAC3C,gBAAgB;IAChB,UAAU;IACV,uBAAuB;;EAEzB;;EAEA;IACE,YAAY;IACZ,mBAAmB;EACrB;;EAEA;IACE,cAAc;IACd,cAAc;IACd,eAAe;EACjB;;EAEA;IACE,eAAe;IACf,gBAAgB;IAChB,cAAc;IACd,mBAAmB;IACnB,mBAAmB;IACnB,6BAA6B;EAC/B;;EAEA;IACE,eAAe;IACf,gBAAgB;IAChB,cAAc;IACd,mBAAmB;IACnB,uBAAuB;EACzB;;EAEA;IACE,qBAAqB;IACrB,mBAAmB;IACnB,YAAY;IACZ,kBAAkB;IAClB,kBAAkB;IAClB,qBAAqB;IACrB,gBAAgB;IAChB,iCAAiC;IACjC,uBAAuB;EACzB;;EAEA;IACE,mBAAmB;EACrB","sourcesContent":[".verificationSuccess {\n    min-height: 100vh;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    background-color: #f5f7f9;\n    padding: 20px;\n    font-family: sans-serif;\n\n  }\n  \n  .card {\n    background: white;\n    border-radius: 20px;\n    padding: 40px;\n    text-align: center;\n    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.08);\n    max-width: 500px;\n    width: 90%;\n    font-family: sans-serif;\n\n  }\n  \n  .logo {\n    width: 185px;\n    margin-bottom: 30px;\n  }\n  \n  .iconContainer {\n    margin: 30px 0;\n    color: #4CAF50;\n    font-size: 64px;\n  }\n  \n  .heading {\n    font-size: 32px;\n    font-weight: 600;\n    color: #2c3e50;\n    margin-bottom: 20px;\n    white-space: nowrap;\n    /* font-family: sans-serif; */\n  }\n  \n  .message {\n    font-size: 16px;\n    line-height: 1.6;\n    color: #546e7a;\n    margin-bottom: 30px;\n    font-family: sans-serif;\n  }\n  \n  .loginButton {\n    display: inline-block;\n    background: #3c5a99;\n    color: white;\n    padding: 15px 30px;\n    border-radius: 8px;\n    text-decoration: none;\n    font-weight: 500;\n    transition: background-color 0.2s;\n    font-family: sans-serif;\n  }\n  \n  .loginButton:hover {\n    background: #2e497a;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"verificationSuccess": `EmailVerification_verificationSuccess__b3Wn7`,
	"card": `EmailVerification_card__+wXBP`,
	"logo": `EmailVerification_logo__oXThl`,
	"iconContainer": `EmailVerification_iconContainer__I5RXN`,
	"heading": `EmailVerification_heading__Tnxlc`,
	"message": `EmailVerification_message__gzm+p`,
	"loginButton": `EmailVerification_loginButton__kkyVT`
};
export default ___CSS_LOADER_EXPORT___;
