// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TermsAndPrivacy_container__ZW160 {
    max-width: 800px;
    margin: 0 auto;
    padding: 40px 20px;
    font-family: system-ui, -apple-system, sans-serif;
    color: #333;
    line-height: 1.6;
  }
  
  .TermsAndPrivacy_section__U2HIf {
    margin-bottom: 40px;
  }
  
  h1 {
    font-size: 32px;
    color: #2c5282;
    margin-bottom: 20px;
  }
  
  h2 {
    font-size: 24px;
    color: #2d3748;
    margin: 30px 0 20px;
    padding-bottom: 10px;
    border-bottom: 2px solid #edf2f7;
  }
  
  h3 {
    font-size: 20px;
    color: #4a5568;
    margin: 25px 0 15px;
  }
  
  p {
    margin: 15px 0;
    color: #4a5568;
  }
  
  ul {
    margin: 15px 0;
    padding-left: 20px;
  }
  
  li {
    margin: 8px 0;
    color: #4a5568;
  }
  
  @media (max-width: 768px) {
    .TermsAndPrivacy_container__ZW160 {
      padding: 20px 15px;
    }
  
    h1 {
      font-size: 28px;
    }
  
    h2 {
      font-size: 22px;
    }
  
    h3 {
      font-size: 18px;
    }
  }`, "",{"version":3,"sources":["webpack://./src/components/TermsAndPrivacy.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,cAAc;IACd,kBAAkB;IAClB,iDAAiD;IACjD,WAAW;IACX,gBAAgB;EAClB;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,eAAe;IACf,cAAc;IACd,mBAAmB;EACrB;;EAEA;IACE,eAAe;IACf,cAAc;IACd,mBAAmB;IACnB,oBAAoB;IACpB,gCAAgC;EAClC;;EAEA;IACE,eAAe;IACf,cAAc;IACd,mBAAmB;EACrB;;EAEA;IACE,cAAc;IACd,cAAc;EAChB;;EAEA;IACE,cAAc;IACd,kBAAkB;EACpB;;EAEA;IACE,aAAa;IACb,cAAc;EAChB;;EAEA;IACE;MACE,kBAAkB;IACpB;;IAEA;MACE,eAAe;IACjB;;IAEA;MACE,eAAe;IACjB;;IAEA;MACE,eAAe;IACjB;EACF","sourcesContent":[".container {\n    max-width: 800px;\n    margin: 0 auto;\n    padding: 40px 20px;\n    font-family: system-ui, -apple-system, sans-serif;\n    color: #333;\n    line-height: 1.6;\n  }\n  \n  .section {\n    margin-bottom: 40px;\n  }\n  \n  h1 {\n    font-size: 32px;\n    color: #2c5282;\n    margin-bottom: 20px;\n  }\n  \n  h2 {\n    font-size: 24px;\n    color: #2d3748;\n    margin: 30px 0 20px;\n    padding-bottom: 10px;\n    border-bottom: 2px solid #edf2f7;\n  }\n  \n  h3 {\n    font-size: 20px;\n    color: #4a5568;\n    margin: 25px 0 15px;\n  }\n  \n  p {\n    margin: 15px 0;\n    color: #4a5568;\n  }\n  \n  ul {\n    margin: 15px 0;\n    padding-left: 20px;\n  }\n  \n  li {\n    margin: 8px 0;\n    color: #4a5568;\n  }\n  \n  @media (max-width: 768px) {\n    .container {\n      padding: 20px 15px;\n    }\n  \n    h1 {\n      font-size: 28px;\n    }\n  \n    h2 {\n      font-size: 22px;\n    }\n  \n    h3 {\n      font-size: 18px;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `TermsAndPrivacy_container__ZW160`,
	"section": `TermsAndPrivacy_section__U2HIf`
};
export default ___CSS_LOADER_EXPORT___;
