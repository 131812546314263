// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Loading_loadingOverlay__5FaCy {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    backdrop-filter: blur(4px);
  }
  
  .Loading_loadingContainer__RBShV {
    background-color: white;
    padding: 2rem;
    border-radius: 12px;
    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.15);
    display: flex;
    flex-direction: column;
    align-items: center;
    animation: Loading_slideDown__mA1RN 0.3s ease-out;
  }
  
  .Loading_loadingSpinner__lKU8G {
    width: 50px;
    height: 50px;
    border: 4px solid #f3f3f3;
    border-top: 4px solid #004788;
    border-radius: 50%;
    animation: Loading_spin__43u8T 1s linear infinite;
    margin-bottom: 1.5rem;
  }
  
  .Loading_loadingText__sw6ct {
    color: #333;
    font-size: 16px;
    margin: 0;
    font-weight: 500;
  }
  
  @keyframes Loading_spin__43u8T {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  @keyframes Loading_slideDown__mA1RN {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }`, "",{"version":3,"sources":["webpack://./src/components/common/Loading.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,QAAQ;IACR,SAAS;IACT,oCAAoC;IACpC,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;IACb,0BAA0B;EAC5B;;EAEA;IACE,uBAAuB;IACvB,aAAa;IACb,mBAAmB;IACnB,0CAA0C;IAC1C,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,iDAAkC;EACpC;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,yBAAyB;IACzB,6BAA6B;IAC7B,kBAAkB;IAClB,iDAAkC;IAClC,qBAAqB;EACvB;;EAEA;IACE,WAAW;IACX,eAAe;IACf,SAAS;IACT,gBAAgB;EAClB;;EAEA;IACE,KAAK,uBAAuB,EAAE;IAC9B,OAAO,yBAAyB,EAAE;EACpC;;EAEA;IACE;MACE,UAAU;MACV,4BAA4B;IAC9B;IACA;MACE,UAAU;MACV,wBAAwB;IAC1B;EACF","sourcesContent":[".loadingOverlay {\n    position: fixed;\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n    background-color: rgba(0, 0, 0, 0.7);\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    z-index: 1000;\n    backdrop-filter: blur(4px);\n  }\n  \n  .loadingContainer {\n    background-color: white;\n    padding: 2rem;\n    border-radius: 12px;\n    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.15);\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    animation: slideDown 0.3s ease-out;\n  }\n  \n  .loadingSpinner {\n    width: 50px;\n    height: 50px;\n    border: 4px solid #f3f3f3;\n    border-top: 4px solid #004788;\n    border-radius: 50%;\n    animation: spin 1s linear infinite;\n    margin-bottom: 1.5rem;\n  }\n  \n  .loadingText {\n    color: #333;\n    font-size: 16px;\n    margin: 0;\n    font-weight: 500;\n  }\n  \n  @keyframes spin {\n    0% { transform: rotate(0deg); }\n    100% { transform: rotate(360deg); }\n  }\n  \n  @keyframes slideDown {\n    from {\n      opacity: 0;\n      transform: translateY(-20px);\n    }\n    to {\n      opacity: 1;\n      transform: translateY(0);\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loadingOverlay": `Loading_loadingOverlay__5FaCy`,
	"loadingContainer": `Loading_loadingContainer__RBShV`,
	"slideDown": `Loading_slideDown__mA1RN`,
	"loadingSpinner": `Loading_loadingSpinner__lKU8G`,
	"spin": `Loading_spin__43u8T`,
	"loadingText": `Loading_loadingText__sw6ct`
};
export default ___CSS_LOADER_EXPORT___;
