import axios from "axios";
import config from "../config";

// Remove the direct hook usage
let showAlertFunction = null;

// Add a function to set the alert handler
export const setAlertHandler = (alertFn) => {
  showAlertFunction = alertFn;
};

// Create two axios instances - one for auth, one for protected routes
const authApi = axios.create({
  baseURL: config.apiBaseUrl,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
  },
  timeout: 10000, // 10 second timeout
  retries: 3,
  retryDelay: 1000,
  // Remove CSRF settings for auth endpoints
  xsrfCookieName: null,
  xsrfHeaderName: null,
});

// Add request interceptor to exclude CSRF for specific endpoints
authApi.interceptors.request.use(
  async (config) => {
    // List of endpoints that don't need CSRF
    const noCSRFEndpoints = [
      "/api/auth/login/",
      "/api/auth/register/",
      "/api/auth/google/",
      "/api/forgotPassword",
      // "/api/auth/logout/",
    ];

    // Only add CSRF token if the endpoint is not in the exclusion list
    if (!noCSRFEndpoints.includes(config.url)) {
      const csrfToken = getCsrfToken();
      if (csrfToken) {
        config.headers["X-CSRFToken"] = csrfToken;
      }
    }

    console.log("Request Config:", {
      url: config.url,
      method: config.method,
      headers: config.headers,
    });

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
// Add interceptor for retry logic
// authApi.interceptors.response.use(null, async (error) => {
//   const { config } = error;
//   if (!config || !config.retries || error.response?.status >= 400) {
//     return Promise.reject(error);
//   }

//   config.retries -= 1;
//   if (config.retries === 0) {
//     return Promise.reject(error);
//   }

//   // Exponential backoff
//   const delay = config.retryDelay * (3 - config.retries);
//   await new Promise((resolve) => setTimeout(resolve, delay));

//   return authApi(config);
// });
const protectedApi = axios.create({
  baseURL: config.apiBaseUrl,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
  },
});

// Request interceptor
protectedApi.interceptors.request.use(
  async (config) => {
    try {
      // console.log("Current cookies:", document.cookie);
      console.log("Request Config:", {
        url: config.url,
        method: config.method,
        headers: config.headers,
      });
      const csrfToken = getCsrfToken();
      if (csrfToken) {
        // console.log("Setting CSRF token:", csrfToken);
        config.headers["X-CSRFToken"] = csrfToken;
        localStorage.setItem("csrfToken", csrfToken);
      } else {
        console.warn("[WARNING] No CSRF token found");
        if (showAlertFunction) {
          await showAlertFunction(
            "Something wrong while login. Please try again later or contact the team",
            {
              onClose: () => {
                window.location.href = "/login";
              },
            }
          );
        } else {
          window.location.href = "/login";
        }
        return Promise.reject(new Error("No CSRF token"));
      }

      // For multipart/form-data requests, we need to preserve the Content-Type
      if (config.data instanceof FormData) {
        config.headers["Content-Type"] = "multipart/form-data";
      }

      // IMPORTANT: Add these conditions to prevent infinite loop
      const isApplicationEndpoint =
        config.url === "/api/application/" ||
        config.url.startsWith("/api/application");
      const isGetMethod = config.method.toLowerCase() === "get";

      // Skip application ID check for application-related GET requests
      if (isApplicationEndpoint && isGetMethod) {
        return config;
      }

      // Add Application ID for other requests
      const applicationId = await checkApplicationId();
      if (applicationId) {
        localStorage.setItem("applicationId", applicationId);
        config.headers["X-Application-ID"] = applicationId;
      }

      console.log("Request Config:", {
        url: config.url,
        method: config.method,
        headers: config.headers,
      });

      return config;
    } catch (error) {
      console.error("[ERROR] Request interceptor failed:", error);
      return Promise.reject(error);
    }
  },
  (error) => {
    return Promise.reject(error);
  }
);
const resetApplicationIdPromise = () => {
  applicationIdPromise = null;
  localStorage.removeItem("applicationId");
  console.log("applicationIdPromise reset to null");
};

// Modify getApplicationId to cache the promise
let applicationIdPromise = null;
const getApplicationId = async () => {
  if (applicationIdPromise) {
    try {
      const timeoutPromise = new Promise((_, reject) =>
        setTimeout(
          () => reject(new Error("Application ID promise timeout")),
          5000
        )
      );
      const result = await Promise.race([applicationIdPromise, timeoutPromise]);
      console.log("Application ID promise result:", result);
      return result;
    } catch (error) {
      console.error("Application ID promise timeout:", error);
      resetApplicationIdPromise();
      throw error;
    }
  }

  applicationIdPromise = (async () => {
    try {
      const storedAppId = localStorage.getItem("applicationId");
      if (storedAppId) {
        // Validate stored ID format/value before returning
        if (
          storedAppId &&
          storedAppId !== "null" &&
          storedAppId !== "undefined"
        ) {
          return storedAppId;
        }
        // Clear invalid stored ID
        localStorage.removeItem("applicationId");
      }

      const response = await protectedApi.get("/api/application/");
      if (!response?.data?.results) {
        throw new Error("Invalid response format");
      }

      if (response.data.results.length > 0) {
        const currentApplication =
          response.data.results[response.data.results.length - 1];
        localStorage.setItem("applicationId", currentApplication.id);
        return currentApplication.id;
      }

      return null;
    } catch (error) {
      // Reset promise on error so future calls can retry
      applicationIdPromise = null;
      console.error("Error getting application ID:", error);
      return null;
    }
  })();

  return applicationIdPromise;
};

// Add debug logging to getCsrfToken
function getCsrfToken() {
  try {
    if (typeof document === "undefined" || !document.cookie) {
      console.warn("[WARNING] Document or cookies not accessible");
      console.log("Current location:", window.location.hostname);
      console.log("Cookie status:", {
        cookieEnabled: navigator.cookieEnabled,
        cookieString: document.cookie,
        protocol: window.location.protocol,
      });
      return null;
    }
    const value = `; ${document.cookie}`;
    const parts = value.split(`; csrftoken=`);
    console.log("[DEBUG] Cookie parsing:", {
      fullCookieString: value,
      foundParts: parts.length,
      isHTTPS: window.location.protocol === "https:",
    });
    if (parts.length === 2) {
      const token = parts.pop().split(";").shift();
      console.log("[DEBUG] Found CSRF token:", token);
      return token;
    }
    return null;
  } catch (error) {
    console.error("[ERROR] Cookie access error:", error);
    return null;
  }
}

// Add response interceptor to handle cookie setting
authApi.interceptors.response.use(
  (response) => {
    // Check if we got a csrftoken cookie
    const cookies = document.cookie.split(";");
    const csrfCookie = cookies.find((cookie) =>
      cookie.trim().startsWith("csrftoken=")
    );

    if (!csrfCookie && response.headers["set-cookie"]) {
      // If the server sent us a cookie but it's not set, manually set it
      const setCookieHeader = response.headers["set-cookie"];
      if (Array.isArray(setCookieHeader)) {
        setCookieHeader.forEach((cookie) => {
          if (cookie.startsWith("csrftoken=")) {
            document.cookie = cookie;
          }
        });
      }
    }
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Modify existing protectedApi response interceptor
protectedApi.interceptors.response.use(
  (response) => {
    try {
      // Calculate request duration
      const duration = response.config.metadata
        ? new Date().getTime() - response.config.metadata.startTime
        : "N/A";

      if (process.env.NODE_ENV === "development") {
        console.log("[DEBUG] API Response:", {
          url: response.config.url,
          status: response.status,
          duration: `${duration}ms`,
        });
      }

      // Log slow requests
      if (duration > 1000) {
        console.warn("[WARNING] Slow request:", {
          url: response.config.url,
          duration: `${duration}ms`,
        });
      }

      return response;
    } catch (error) {
      console.error("[ERROR] Response success handler failed:", error);
      return response;
    }
  },
  async (error) => {
    try {
      const wasHandled = await handleAuthError(error);
      if (wasHandled) {
        return Promise.reject(error);
      }

      const errorDetails = {
        url: error.config?.url,
        method: error.config?.method,
        status: error.response?.status,
        statusText: error.response?.statusText,
        data: error.response?.data,
        duration: error.config?.metadata
          ? new Date().getTime() - error.config.metadata.startTime
          : "N/A",
      };

      // Log different error types appropriately
      if (error.response) {
        console.error("[ERROR] Server response error:", errorDetails);
      } else if (error.request) {
        console.error("[ERROR] No response received:", errorDetails);
      } else {
        console.error("[ERROR] Request configuration error:", error.message);
      }

      return Promise.reject(error);
    } catch (interceptorError) {
      console.error("[ERROR] Response error handler failed:", interceptorError);
      return Promise.reject(error);
    }
  }
);

const handleAuthError = async (error) => {
  // Handle missing application ID
  if (error.message === "No active application") {
    if (showAlertFunction) {
      await showAlertFunction(
        "No active application found. Please create a personal profile first.",
        {
          onClose: () => {
            window.location.href = "/personal-info";
          },
        }
      );
    } else {
      window.location.href = "/personal-info";
    }
    return true;
  }

  // Existing auth error handling
  if (
    error.response?.status === 403 &&
    (error.response?.data?.detail?.includes("Authentication") ||
      error.response?.data?.detail?.includes("CSRF"))
  ) {
    await authApi.post("/api/auth/logout/");
    // Clear storage
    localStorage.clear();
    sessionStorage.clear();

    // Clear cookies without specifying path or domain
    document.cookie.split(";").forEach((cookie) => {
      const [name] = cookie.split("=");
      const trimmedName = name.trim();
      document.cookie = `${trimmedName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; secure; samesite=lax`;
    });

    // Show alert and redirect
    if (showAlertFunction) {
      await showAlertFunction(
        "Something went wrong while login. Please login again."
      );
    }

    // Only redirect if not already on login page
    if (!window.location.pathname.includes("/login")) {
      window.location.href = "/login";
    }
    return true;
  }
  return false;
};

export const checkApplicationId = async () => {
  const applicationId = await getApplicationId();
  if (!applicationId) {
    const error = new Error("No active application");
    await handleAuthError(error);
    return false;
  }
  return applicationId;
};

export {
  authApi,
  protectedApi,
  getCsrfToken,
  resetApplicationIdPromise,
  handleAuthError,
};
