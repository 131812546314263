import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./proposed_endeavor.module.css";
import Sidebar from "../common/Sidebar";
import Header2 from "../common/Header2";
import Button from "../common/Button";
import { handleAuthError, protectedApi } from "../../services/api";
import { FaEdit, FaTrash } from "react-icons/fa";
import { checkUsageLimits } from "../../services/crudHelpers";
import { useAlert } from "../common/AlertContext.js";

function ProposedEndeavor() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [isGenerating, setIsGenerating] = useState(false);
  const [isAIGenerated, setIsAIGenerated] = useState(false);
  const [editingIndex, setEditingIndex] = useState(null);
  const [editForm, setEditForm] = useState(null);

  const [showInput, setShowInput] = useState(false);
  const [submittedEndeavors, setSubmittedEndeavors] = useState([]);
  const [selectedEndeavors, setSelectedEndeavors] = useState([]);
  const [expandedEndeavors, setExpandedEndeavors] = useState({});
  const [endeavorForm, setEndeavorForm] = useState({
    title: "",
    exp_applicant_qualifications: "",
    exp_related_experience: "",
    exp_subs_merit_intric_val: "",
    exp_subs_merit_impact: "",
    exp_subs_merit_evidence: "",
    exp_nation_impt_scope: "",
    exp_nation_impt_urgency: "",
    exp_nation_impt_beneficiaries: "",
    exp_nation_impt_impact: "",
    is_selected: false,
    is_locked: false,
  });

  const { showAlert } = useAlert();

  const formatEndeavorData = (endeavor) => ({
    id: endeavor.id || null,
    title: endeavor.title || "",
    exp_applicant_qualifications: endeavor.exp_applicant_qualifications || "",
    exp_related_experience: endeavor.exp_related_experience || "",
    exp_subs_merit_intric_val: endeavor.exp_subs_merit_intric_val || "",
    exp_subs_merit_impact: endeavor.exp_subs_merit_impact || "",
    exp_subs_merit_evidence: endeavor.exp_subs_merit_evidence || "",
    exp_nation_impt_scope: endeavor.exp_nation_impt_scope || "",
    exp_nation_impt_urgency: endeavor.exp_nation_impt_urgency || "",
    exp_nation_impt_beneficiaries: endeavor.exp_nation_impt_beneficiaries || "",
    exp_nation_impt_impact: endeavor.exp_nation_impt_impact || "",
    is_selected: endeavor.is_selected || false,
    is_locked: endeavor.is_locked || false,
  });

  const formatEndeavorForApi = (endeavor) => ({
    title: endeavor.title || "",
    exp_applicant_qualifications: endeavor.exp_applicant_qualifications || "",
    exp_related_experience: endeavor.exp_related_experience || "",
    exp_subs_merit_intric_val: endeavor.exp_subs_merit_intric_val || "",
    exp_subs_merit_impact: endeavor.exp_subs_merit_impact || "",
    exp_subs_merit_evidence: endeavor.exp_subs_merit_evidence || "",
    exp_nation_impt_scope: endeavor.exp_nation_impt_scope || "",
    exp_nation_impt_urgency: endeavor.exp_nation_impt_urgency || "",
    exp_nation_impt_beneficiaries: endeavor.exp_nation_impt_beneficiaries || "",
    exp_nation_impt_impact: endeavor.exp_nation_impt_impact || "",
    is_selected: endeavor.is_selected || false,
    is_locked: endeavor.is_locked || false,
  });

  const handleError = (error, customMessage = "An error occurred") => {
    console.error(customMessage, error);
    if (error.response?.status === 403) {
      return true;
    }
    showAlert(`${customMessage}. Please try again.`);
    return false;
  };

  useEffect(() => {
    const fetchExistingEndeavors = async () => {
      try {
        const applicationId = localStorage.getItem("applicationId");
        if (!applicationId) {
          console.log("No application ID found");
          return;
        }

        const response = await protectedApi.get(
          "/api/product/proposed-endeavor/",
          {
            headers: {
              "Content-Type": "application/json",
              "X-Application-ID": applicationId,
            },
          }
        );

        if (response.status === 200) {
          const formattedEndeavors = response.data.map(formatEndeavorData);
          setSubmittedEndeavors(formattedEndeavors);
          const selectedIndices = formattedEndeavors
            .map((endeavor, index) => (endeavor.is_selected ? index : null))
            .filter((index) => index !== null);
          setSelectedEndeavors(selectedIndices);
        }
      } catch (error) {
        if (error.response?.data?.error === "Application does not exist") {
          handleError(
            error,
            "Application is not found, please create your profile first"
          );
          navigate("/personal-info");
        } else {
          handleError(error, "Error fetching endeavors");
        }
      } finally {
        setIsInitialLoading(false);
      }
    };

    fetchExistingEndeavors();
  }, []);

  const handleEdit = (index) => {
    setEditingIndex(index);
    setEditForm({ ...submittedEndeavors[index] });
    setExpandedEndeavors((prev) => ({ ...prev, [index]: true }));
  };

  const handleEditFormChange = (e, field) => {
    setEditForm((prev) => ({
      ...prev,
      [field]: e.target.value,
    }));
  };

  const handleDelete = async (index) => {
    try {
      const endeavor = submittedEndeavors[index];
      const applicationId = localStorage.getItem("applicationId");

      if (endeavor.id) {
        const response = await protectedApi.delete(
          `/api/product/proposed-endeavor/${endeavor.id}/`,
          {
            headers: {
              "Content-Type": "application/json",
              "X-Application-ID": applicationId,
            },
          }
        );

        if (response.status === 204) {
          setSubmittedEndeavors((prev) => prev.filter((_, i) => i !== index));
          setSelectedEndeavors((prev) => prev.filter((i) => i !== index));
          console.log("Endeavor deleted successfully");
          showAlert("Endeavor deleted successfully");
        }
      } else {
        setSubmittedEndeavors((prev) => prev.filter((_, i) => i !== index));
      }
    } catch (error) {
      handleError(error, "Error deleting endeavor");
    }
  };

  const handleUpdateEndeavor = async (e) => {
    e.preventDefault();
    try {
      const applicationId = localStorage.getItem("applicationId");
      const endeavor = submittedEndeavors[editingIndex];

      if (endeavor.id) {
        const response = await protectedApi.patch(
          `/api/product/proposed-endeavor/${endeavor.id}/`,
          formatEndeavorForApi(editForm),
          {
            headers: {
              "Content-Type": "application/json",
              "X-Application-ID": applicationId,
            },
          }
        );

        if (response.status === 200) {
          const updatedEndeavors = [...submittedEndeavors];
          updatedEndeavors[editingIndex] = formatEndeavorData(response.data);
          setSubmittedEndeavors(updatedEndeavors);
          console.log("Endeavor updated successfully");
          showAlert("Endeavor updated successfully");
        }
      }
    } catch (error) {
      handleError(error, "Error updating endeavor");
    } finally {
      setEditingIndex(null);
      setEditForm(null);
    }
  };

  const handleLockCheck = async (endeavor) => {
    if (endeavor.is_locked) {
      const confirmPurchase = await showAlert(
        "This endeavor is locked. Upgrade your plan to unlock additional endeavors.",
        "View Plans",
        "Cancel"
      );
      if (confirmPurchase) {
        navigate("/plans");
      }
      return true;
    }
    return false;
  };

  const handleCheckboxChange = async (index, e) => {
    if (e) {
      e.stopPropagation();
    }
    const endeavor = submittedEndeavors[index];

    if (await handleLockCheck(endeavor)) {
      return;
    }

    try {
      const applicationId = localStorage.getItem("applicationId");
      if (!endeavor.id) {
        console.error("Cannot update endeavor: No ID found");
        return;
      }

      const updatedEndeavor = {
        ...formatEndeavorForApi(endeavor),
        is_selected: !endeavor.is_selected,
      };

      const response = await protectedApi.patch(
        `/api/product/proposed-endeavor/${endeavor.id}/`,
        updatedEndeavor,
        {
          headers: {
            "Content-Type": "application/json",
            "X-Application-ID": applicationId,
          },
        }
      );

      if (response.status === 200) {
        const updatedEndeavors = [...submittedEndeavors];
        updatedEndeavors[index] = formatEndeavorData(response.data);
        setSubmittedEndeavors(updatedEndeavors);

        if (response.data.is_selected) {
          setSelectedEndeavors((prev) => [...prev, index]);
          showAlert("Endeavor selected successfully");
        } else {
          setSelectedEndeavors((prev) => prev.filter((i) => i !== index));
          showAlert("Endeavor deselected successfully");
        }
      }
    } catch (error) {
      console.error("Error in handleCheckboxChange:", error);
      handleError(error, "Error updating endeavor selection");
    }
  };

  const handleAddEndeavor = () => {
    setShowInput(true);
  };

  const handleFormChange = (e, section, subsection = null) => {
    if (subsection) {
      setEndeavorForm((prev) => ({
        ...prev,
        [section]: {
          ...prev[section],
          [subsection]: e.target.value,
        },
      }));
    } else {
      setEndeavorForm((prev) => ({
        ...prev,
        [section]: e.target.value,
      }));
    }
  };

  const handleSubmitEndeavor = async (e) => {
    e.preventDefault();
    try {
      const applicationId = localStorage.getItem("applicationId");
      const formattedEndeavor = {
        ...formatEndeavorForApi(endeavorForm),
        application_id: applicationId,
      };

      const response = await protectedApi.post(
        "/api/product/proposed-endeavor/",
        formattedEndeavor,
        {
          headers: {
            "Content-Type": "application/json",
            "X-Application-ID": applicationId,
          },
        }
      );

      if (response.status === 201) {
        setSubmittedEndeavors((prev) => [
          ...prev,
          formatEndeavorData(response.data),
        ]);
        // Reset form
        setEndeavorForm({
          title: "",
          exp_applicant_qualifications: "",
          exp_related_experience: "",
          exp_subs_merit_intric_val: "",
          exp_subs_merit_impact: "",
          exp_subs_merit_evidence: "",
          exp_nation_impt_scope: "",
          exp_nation_impt_urgency: "",
          exp_nation_impt_beneficiaries: "",
          exp_nation_impt_impact: "",
          is_selected: false,
          is_locked: false,
        });
        setIsAIGenerated(false);
        setShowInput(false);
        console.log("Endeavor submitted successfully");
        showAlert("Endeavor submitted successfully");
      }
    } catch (error) {
      handleError(error, "Error submitting endeavor");
    }
  };

  const handleContinue = () => {
    if (submittedEndeavors.length === 0) {
      showAlert("Please add at least one proposed endeavor before continuing");
      return;
    }
    navigate("/recommender");
  };

  const handlePrevious = () => {
    navigate("/future-plans");
  };

  const toggleEndeavor = (index, e) => {
    e.stopPropagation();
    setExpandedEndeavors((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const handleGenerate = async () => {
    const usageCheck = await checkUsageLimits("Proposed Endeavors", showAlert);

    if (!usageCheck.success) {
      if (usageCheck.action === "redirect") {
        navigate("/plans");
      }
      showAlert(usageCheck.message);
      return;
    }

    setIsGenerating(true);
    try {
      const applicationId = localStorage.getItem("applicationId");
      if (!applicationId) {
        showAlert(
          "No active application found. Please create an application first."
        );
        navigate("/personal-info");
        return;
      }

      const response = await protectedApi.post(
        "/api/product/proposed-endeavor/generate/",
        {},
        {
          headers: {
            "Content-Type": "application/json",
            "X-Application-ID": applicationId,
          },
        }
      );

      if (response.status === 200 && response.data.task_id) {
        // Poll for task status
        const pollInterval = setInterval(async () => {
          const statusResponse = await protectedApi.get(
            `/api/task-status/${response.data.task_id}/`
          );

          if (statusResponse.data.status === "SUCCESS") {
            clearInterval(pollInterval);
            // Fetch the updated endeavors
            const endeavorsResponse = await protectedApi.get(
              "/api/product/proposed-endeavor/",
              {
                headers: {
                  "Content-Type": "application/json",
                  "X-Application-ID": applicationId,
                },
              }
            );

            if (endeavorsResponse.status === 200) {
              const formattedEndeavors =
                endeavorsResponse.data.map(formatEndeavorData);
              setSubmittedEndeavors(formattedEndeavors);
              setShowInput(false);
              setIsAIGenerated(true);
              setIsGenerating(false);
            }
          } else if (statusResponse.data.status === "FAILURE") {
            clearInterval(pollInterval);
            setIsGenerating(false);
            throw new Error("Generation failed");
          }
        }, 2000);

        // Stop polling after 5 minutes
        setTimeout(() => {
          clearInterval(pollInterval);
          if (isGenerating) {
            setIsGenerating(false);
            showAlert("Generation timed out. Please try again.");
          }
        }, 300000);
      }
    } catch (error) {
      console.error("Error generating endeavors:", error);
      const errorMessage =
        error.response?.data?.error || "Failed to generate endeavors";

      if (error.response?.status === 403) {
        handleAuthError(error);
        return;
      }
      showAlert(
        errorMessage || "Failed to generate endeavors. Please try again."
      );
      setIsGenerating(false);
    }
  };

  const generatorContent = () => {
    if (isInitialLoading) {
      return <p>Loading...</p>;
    }

    if (isAIGenerated) {
      return (
        <p className={styles.generatedMessage}>
          ✓ Endeavors have been generated. Please review and select the ones
          you'd like to use.
        </p>
      );
    }

    return (
      <>
        <p>Generate 10 endeavors based on your profile</p>
        <div className={styles.actionButtons}>
          <button
            className={styles.generateButton}
            onClick={handleGenerate}
            disabled={isGenerating}
          >
            {isGenerating ? "Generating..." : "Generate Now"}
          </button>
        </div>
      </>
    );
  };

  return (
    <div className={styles.container}>
      <Header2 />
      <div className={styles.pageLayout}>
        <Sidebar />
        <div className={styles.mainContent}>
          <div className={styles.wrapper}>
            <h1 className={styles.title}>Proposed Endeavor</h1>

            <div className={styles.aiGeneratorCard}>
              <div className={styles.generatorHeader}>
                <div className={styles.headerLeft}>
                  <span className={styles.aiIcon}>✨</span>
                  <span>Endeavor AI Generator</span>
                </div>
                <button className={styles.collapseButton}>
                  <span>▼</span>
                </button>
              </div>

              <div className={styles.generatorContent}>
                {generatorContent()}
              </div>
            </div>

            <div className={styles.endeavorsContainer}>
              {submittedEndeavors.length > 0 && (
                <div className={styles.submittedEndeavorsContainer}>
                  <h2 className={styles.submittedEndeavorsTitle}>
                    Your Proposed Endeavors
                  </h2>
                  {submittedEndeavors.map((endeavor, index) => (
                    <div key={index} className={styles.submittedEndeavor}>
                      <div className={styles.endeavorHeader}>
                        <div className={styles.checkboxContainer}>
                          <input
                            type="checkbox"
                            checked={selectedEndeavors.includes(index)}
                            onChange={(e) => handleCheckboxChange(index, e)}
                            className={styles.endeavorCheckbox}
                          />
                        </div>
                        <h2
                          className={styles.endeavorTitle}
                          onClick={async (e) => {
                            const endeavor = submittedEndeavors[index];
                            if (!(await handleLockCheck(endeavor))) {
                              toggleEndeavor(index, e);
                            }
                          }}
                        >
                          {endeavor.title || `Endeavor ${index + 1}`}
                        </h2>
                        <div className={styles.endeavorActions}>
                          <button
                            className={styles.iconButton}
                            onClick={() => handleEdit(index)}
                            title="Edit"
                          >
                            <FaEdit />
                          </button>
                          <button
                            className={styles.iconButton}
                            onClick={() => handleDelete(index)}
                            title="Delete"
                          >
                            <FaTrash />
                          </button>
                          <button
                            className={`${styles.dropdownButton} ${
                              expandedEndeavors[index] ? styles.expanded : ""
                            }`}
                            onClick={(e) => toggleEndeavor(index, e)}
                          >
                            ▼
                          </button>
                        </div>
                      </div>

                      {expandedEndeavors[index] && (
                        <div className={styles.endeavorDetails}>
                          {editingIndex === index ? (
                            <form
                              onSubmit={handleUpdateEndeavor}
                              className={styles.editForm}
                            >
                              <div className={styles.formSection}>
                                <h3>Title</h3>
                                <input
                                  type="text"
                                  value={editForm.title}
                                  onChange={(e) =>
                                    handleEditFormChange(e, "title")
                                  }
                                  className={styles.textInput}
                                  required
                                />
                              </div>

                              <div className={styles.formSection}>
                                <h3>Qualifications</h3>
                                <textarea
                                  value={editForm.exp_applicant_qualifications}
                                  onChange={(e) =>
                                    handleEditFormChange(
                                      e,
                                      "exp_applicant_qualifications"
                                    )
                                  }
                                  className={styles.textarea}
                                  required
                                />
                              </div>

                              <div className={styles.formSection}>
                                <h3>Experience</h3>
                                <textarea
                                  value={editForm.exp_related_experience}
                                  onChange={(e) =>
                                    handleEditFormChange(
                                      e,
                                      "exp_related_experience"
                                    )
                                  }
                                  className={styles.textarea}
                                  required
                                />
                              </div>

                              <div className={styles.formSection}>
                                <h3>Substantial Merit</h3>
                                <div className={styles.subSection}>
                                  <textarea
                                    value={editForm.exp_subs_merit_intric_val}
                                    onChange={(e) =>
                                      handleEditFormChange(
                                        e,
                                        "exp_subs_merit_intric_val"
                                      )
                                    }
                                    placeholder="Intrinsic Value..."
                                    className={styles.textarea}
                                    required
                                  />
                                  <textarea
                                    value={editForm.exp_subs_merit_impact}
                                    onChange={(e) =>
                                      handleEditFormChange(
                                        e,
                                        "exp_subs_merit_impact"
                                      )
                                    }
                                    placeholder="Impact..."
                                    className={styles.textarea}
                                    required
                                  />
                                  <textarea
                                    value={editForm.exp_subs_merit_evidence}
                                    onChange={(e) =>
                                      handleEditFormChange(
                                        e,
                                        "exp_subs_merit_evidence"
                                      )
                                    }
                                    placeholder="Evidence of Achievements..."
                                    className={styles.textarea}
                                    required
                                  />
                                </div>
                              </div>

                              <div className={styles.formSection}>
                                <h3>National Importance</h3>
                                <div className={styles.subSection}>
                                  <textarea
                                    value={editForm.exp_nation_impt_scope}
                                    onChange={(e) =>
                                      handleEditFormChange(
                                        e,
                                        "exp_nation_impt_scope"
                                      )
                                    }
                                    placeholder="Scope..."
                                    className={styles.textarea}
                                    required
                                  />
                                  <textarea
                                    value={editForm.exp_nation_impt_urgency}
                                    onChange={(e) =>
                                      handleEditFormChange(
                                        e,
                                        "exp_nation_impt_urgency"
                                      )
                                    }
                                    placeholder="Urgent Need..."
                                    className={styles.textarea}
                                    required
                                  />
                                  <textarea
                                    value={
                                      editForm.exp_nation_impt_beneficiaries
                                    }
                                    onChange={(e) =>
                                      handleEditFormChange(
                                        e,
                                        "exp_nation_impt_beneficiaries"
                                      )
                                    }
                                    placeholder="Beneficiaries..."
                                    className={styles.textarea}
                                    required
                                  />
                                  <textarea
                                    value={editForm.exp_nation_impt_impact}
                                    onChange={(e) =>
                                      handleEditFormChange(
                                        e,
                                        "exp_nation_impt_impact"
                                      )
                                    }
                                    placeholder="Impact..."
                                    className={styles.textarea}
                                    required
                                  />
                                </div>
                              </div>

                              <div className={styles.formActions}>
                                <button
                                  type="submit"
                                  className={styles.submitButton}
                                >
                                  Save Changes
                                </button>
                                <button
                                  type="button"
                                  className={styles.cancelButton}
                                  onClick={() => {
                                    setEditingIndex(null);
                                    setEditForm(null);
                                  }}
                                >
                                  Cancel
                                </button>
                              </div>
                            </form>
                          ) : (
                            <div>
                              <div>
                                <h3>Qualifications</h3>
                                <p>{endeavor.exp_applicant_qualifications}</p>
                              </div>
                              <div>
                                <h3>Experience</h3>
                                <p>{endeavor.exp_related_experience}</p>
                              </div>
                              <div>
                                <h3>Substantial Merit</h3>
                                <div className={styles.meritSection}>
                                  <p>
                                    <strong>Intrinsic Value:</strong>{" "}
                                    {endeavor.exp_subs_merit_intric_val}
                                  </p>
                                  <p>
                                    <strong>Impact:</strong>{" "}
                                    {endeavor.exp_subs_merit_impact}
                                  </p>
                                  <p>
                                    <strong>Achievements:</strong>{" "}
                                    {endeavor.exp_subs_merit_evidence}
                                  </p>
                                </div>
                              </div>
                              <div>
                                <h3>National Importance</h3>
                                <div
                                  className={styles.nationalImportanceSection}
                                >
                                  <p>
                                    <strong>Scope:</strong>{" "}
                                    {endeavor.exp_nation_impt_scope}
                                  </p>
                                  <p>
                                    <strong>Urgent Need:</strong>{" "}
                                    {endeavor.exp_nation_impt_urgency}
                                  </p>
                                  <p>
                                    <strong>Beneficiaries:</strong>{" "}
                                    {endeavor.exp_nation_impt_beneficiaries}
                                  </p>
                                  <p>
                                    <strong>Impact:</strong>{" "}
                                    {endeavor.exp_nation_impt_impact}
                                  </p>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              )}

              {showInput && (
                <form
                  onSubmit={handleSubmitEndeavor}
                  className={styles.endeavorForm}
                >
                  <div className={styles.formSection}>
                    <input
                      type="text"
                      value={endeavorForm.title}
                      onChange={(e) => handleFormChange(e, "title")}
                      placeholder="Endeavor Title"
                      className={styles.textInput}
                      required
                    />
                  </div>

                  <div className={styles.formSection}>
                    <h3>Applicant Qualifications</h3>
                    <textarea
                      value={endeavorForm.exp_applicant_qualifications}
                      onChange={(e) =>
                        handleFormChange(e, "exp_applicant_qualifications")
                      }
                      placeholder="List your relevant qualifications..."
                      className={styles.textarea}
                      required
                    />
                  </div>

                  <div className={styles.formSection}>
                    <h3>Related Experience</h3>
                    <textarea
                      value={endeavorForm.exp_related_experience}
                      onChange={(e) =>
                        handleFormChange(e, "exp_related_experience")
                      }
                      placeholder="Describe your related experience..."
                      className={styles.textarea}
                      required
                    />
                  </div>

                  <div className={styles.formSection}>
                    <h3>Substantial Merit</h3>
                    <div className={styles.subSection}>
                      <textarea
                        value={endeavorForm.exp_subs_merit_intric_val}
                        onChange={(e) =>
                          handleFormChange(e, "exp_subs_merit_intric_val")
                        }
                        placeholder="Intrinsic Value..."
                        className={styles.textarea}
                        required
                      />
                      <textarea
                        value={endeavorForm.exp_subs_merit_impact}
                        onChange={(e) =>
                          handleFormChange(e, "exp_subs_merit_impact")
                        }
                        placeholder="Impact..."
                        className={styles.textarea}
                        required
                      />
                      <textarea
                        value={endeavorForm.exp_subs_merit_evidence}
                        onChange={(e) =>
                          handleFormChange(e, "exp_subs_merit_evidence")
                        }
                        placeholder="Evidence of Achievements..."
                        className={styles.textarea}
                        required
                      />
                    </div>
                  </div>

                  <div className={styles.formSection}>
                    <h3>National Importance</h3>
                    <div className={styles.subSection}>
                      <textarea
                        value={endeavorForm.exp_nation_impt_scope}
                        onChange={(e) =>
                          handleFormChange(e, "exp_nation_impt_scope")
                        }
                        placeholder="Scope..."
                        className={styles.textarea}
                        required
                      />
                      <textarea
                        value={endeavorForm.exp_nation_impt_urgency}
                        onChange={(e) =>
                          handleFormChange(e, "exp_nation_impt_urgency")
                        }
                        placeholder="Urgent Need..."
                        className={styles.textarea}
                        required
                      />
                      <textarea
                        value={endeavorForm.exp_nation_impt_beneficiaries}
                        onChange={(e) =>
                          handleFormChange(e, "exp_nation_impt_beneficiaries")
                        }
                        placeholder="Beneficiaries..."
                        className={styles.textarea}
                        required
                      />
                      <textarea
                        value={endeavorForm.exp_nation_impt_impact}
                        onChange={(e) =>
                          handleFormChange(e, "exp_nation_impt_impact")
                        }
                        placeholder="Impact..."
                        className={styles.textarea}
                        required
                      />
                    </div>
                  </div>

                  <div className={styles.formActions}>
                    <button type="submit" className={styles.submitButton}>
                      Submit Endeavor
                    </button>
                    <button
                      type="button"
                      className={styles.cancelButton}
                      onClick={() => setShowInput(false)}
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              )}

              <div className={styles.addEndeavorHeader}>
                <button
                  className={styles.addEndeavorButton}
                  onClick={handleAddEndeavor}
                >
                  + Add New Endeavor
                </button>
              </div>
            </div>
            <div className={styles.buttoncontainer}>
              <Button variant="secondary" onClick={handlePrevious}>
                Previous
              </Button>
              <Button
                variant="primary"
                onClick={handleContinue}
                disabled={isLoading}
              >
                {isLoading ? "Processing..." : "Continue"}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProposedEndeavor;
