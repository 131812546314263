import { useState, useEffect } from "react";
import { fetchExistingData, handleFormSubmit } from "./crudHelpers";

export const useCrudOperations = ({
  endpoint,
  initialState,
  transformFetchedData,
  transformSubmitData,
}) => {
  const [items, setItems] = useState([initialState]);
  const [errors, setErrors] = useState([]);
  const [openItems, setOpenItems] = useState([true]);
  const [deletedItems, setDeletedItems] = useState(new Set());
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const applicationId = localStorage.getItem("applicationId");
        if (!applicationId || isInitialized) return;

        const data = await fetchExistingData(endpoint);
        if (data.length > 0) {
          const transformedData = data.map(transformFetchedData);
          setItems(transformedData);
          setOpenItems(new Array(transformedData.length).fill(true));
          setErrors(new Array(transformedData.length).fill({}));
        }
        setIsInitialized(true);
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsInitialized(true);
      }
    };

    fetchData();
  }, [endpoint, transformFetchedData, isInitialized]);

  const handleSubmit = async (action = "save") => {
    try {
      const applicationId = localStorage.getItem("applicationId");
      const results = await handleFormSubmit({
        endpoint,
        items,
        deletedItems,
        transformItem: transformSubmitData,
        applicationId,
      });

      return { success: true, results };
    } catch (error) {
      return {
        success: false,
        error: error.response?.data?.error || error.message,
      };
    }
  };

  const addNewItem = () => {
    setItems((prev) => [...prev, initialState]);
    setOpenItems((prev) => [...prev, true]);
    setErrors((prev) => [...prev, {}]);
  };

  const deleteItem = (indexToDelete) => {
    if (items.length > 0) {
      const itemToDelete = items[indexToDelete];
      if (itemToDelete.id) {
        setDeletedItems((prev) => new Set([...prev, itemToDelete.id]));
      }
      setItems((prev) => prev.filter((_, index) => index !== indexToDelete));
      setOpenItems((prev) =>
        prev.filter((_, index) => index !== indexToDelete)
      );
      setErrors((prev) => prev.filter((_, index) => index !== indexToDelete));
    }
  };

  return {
    items,
    setItems,
    errors,
    setErrors,
    openItems,
    setOpenItems,
    deletedItems,
    handleSubmit,
    addNewItem,
    deleteItem,
  };
};
