import React, { useState, useEffect } from "react";
import styles from "./ScientificProject.module.css";
import { useNavigate } from "react-router-dom";
import Sidebar from "../common/Sidebar";
import Button from "../common/Button";
import Stepper from "../common/ProgressBar.js";
import { protectedApi, handleAuthError } from "../../services/api.js";
import CustomDropdown from "../common/Dropdown";
import { RESEARCH_DOMAINS } from "../../constants/dropdownConsts";
import Header2 from "../common/Header2";
import { usePreviousNavigation } from "../../services/handlePrevious";
import { useAlert } from "../common/AlertContext.js";

const initialProjectState = {
  organization: "",
  research_domain: "",
  project_name: "",
  role: "",
  start_date: "",
  end_date: "",
  background: "",
  goal: "",
  challenge: "",
  solution: "",
  impact: "",
  comments: "",
  funding_source: "",
  funding_source_country: "",
  funding_amount: "",
  is_published: false,
  published_date: "",
  published_url: "",
  published_journal: "",
  citation_count: "",
  essay: null,
  essayName: "",
};

function ScientificProjectPage() {
  const navigate = useNavigate();
  const [projects, setProjects] = useState([initialProjectState]);
  const [errors, setErrors] = useState([{}]);
  const [loading, setLoading] = useState(false);
  const [openProjects, setOpenProjects] = useState([true]);
  const [deletedProjects] = useState(new Set());
  const { showAlert } = useAlert();

  useEffect(() => {
    fetchExistingProjects();
  }, []);

  const fetchExistingProjects = async () => {
    try {
      const response = await protectedApi.get("/api/academicprojects/");

      // Clear any existing projects first
      if (response.data?.results) {
        // Map the results directly, don't add to existing projects
        const projectData = response.data.results.map((project) => ({
          ...initialProjectState, // Start with initial state
          ...project, // Override with fetched data
          essayName: project.essay ? project.essay.split("/").pop() : "",
        }));

        // If no projects exist in response, set one empty project
        if (projectData.length === 0) {
          setProjects([initialProjectState]);
          setOpenProjects([true]);
          setErrors([{}]);
        } else {
          // Otherwise set the fetched projects
          setProjects(projectData);
          setOpenProjects(new Array(projectData.length).fill(true));
          setErrors(new Array(projectData.length).fill({}));
        }
      } else {
        // If no results property, set one empty project
        setProjects([initialProjectState]);
        setOpenProjects([true]);
        setErrors([{}]);
      }
    } catch (error) {
      console.error("Error fetching projects:", error);
      if (handleAuthError(error, navigate)) return;
      // On error, set one empty project
      setProjects([initialProjectState]);
      setOpenProjects([true]);
      setErrors([{}]);
    }
  };

  const validateProject = (project, index) => {
    const errors = {};

    // Required fields validation
    if (!project.organization) errors.organization = "This field is required";
    if (!project.research_domain)
      errors.research_domain = "This field is required";
    if (!project.project_name) errors.project_name = "This field is required";
    if (!project.role) errors.role = "This field is required";
    if (!project.start_date) errors.start_date = "This field is required";
    if (!project.end_date) errors.end_date = "This field is required";
    if (!project.background) errors.background = "This field is required";
    if (!project.goal) errors.goal = "This field is required";
    if (!project.challenge) errors.challenge = "This field is required";
    if (!project.solution) errors.solution = "This field is required";
    if (!project.impact) errors.impact = "This field is required";

    // Make funding fields required only when is_published is true
    if (project.is_published) {
      if (!project.funding_source)
        errors.funding_source = "Required when published";
      if (!project.funding_source_country)
        errors.funding_source_country = "Required when published";
      if (!project.funding_amount)
        errors.funding_amount = "Required when published";

      // Publication fields validation
      if (!project.published_date)
        errors.published_date = "Required when published";
      if (!project.published_url)
        errors.published_url = "Required when published";
      if (!project.published_journal)
        errors.published_journal = "Required when published";
      if (!project.citation_count)
        errors.citation_count = "Required when published";
    }

    // Add type validation for funding_amount when it's provided
    if (
      project.funding_amount &&
      !/^\d+(\.\d{0,2})?$/.test(project.funding_amount)
    ) {
      errors.funding_amount = "Please enter a valid amount (e.g., 1000.00)";
    }

    return errors;
  };

  const handleChange = (e, index) => {
    const { name, value, type, checked } = e.target;
    console.log(
      `Handling change for field: ${name}, value: ${value}, type: ${type}, index: ${index}`
    );

    setProjects((prevProjects) => {
      const updatedProjects = [...prevProjects];
      updatedProjects[index] = {
        ...updatedProjects[index],
        [name]: type === "checkbox" ? checked : value,
      };
      console.log("Updated project:", updatedProjects[index]);
      return updatedProjects;
    });
  };

  const handleFileUpload = async (e, index) => {
    const file = e.target.files[0];
    const maxSize = 5 * 1024 * 1024; // 5MB

    if (file) {
      if (file.size > maxSize) {
        setErrors((prev) => {
          const newErrors = [...prev];
          newErrors[index] = {
            ...newErrors[index],
            essay: "File size should not exceed 5MB",
          };
          return newErrors;
        });
        return;
      }

      try {
        setProjects((prevProjects) => {
          const updatedProjects = [...prevProjects];
          updatedProjects[index] = {
            ...updatedProjects[index],
            essay: file,
            essayName: file.name,
          };
          return updatedProjects;
        });
      } catch (error) {
        console.error("Error handling file upload:", error);
        setErrors((prev) => {
          const newErrors = [...prev];
          newErrors[index] = {
            ...newErrors[index],
            essay: "Error uploading file",
          };
          return newErrors;
        });
      }
    }
  };

  const handleRemoveFile = (index) => {
    setProjects((prevProjects) => {
      const updatedProjects = [...prevProjects];
      updatedProjects[index] = {
        ...updatedProjects[index],
        essay: null,
        essayName: "",
      };
      return updatedProjects;
    });

    // Clear any file-related errors
    setErrors((prev) => {
      const newErrors = [...prev];
      if (newErrors[index]) {
        delete newErrors[index].essay;
      }
      return newErrors;
    });
  };

  const handleFormSubmit = async (e, action = "save") => {
    e.preventDefault();
    setLoading(true);

    try {
      const validationErrors = projects.map(validateProject);
      const hasErrors = validationErrors.some(
        (error) => Object.keys(error).length > 0
      );

      if (hasErrors) {
        setErrors(validationErrors);
        // Create detailed error message
        const errorMessages = validationErrors
          .map((projectErrors, index) => {
            if (Object.keys(projectErrors).length === 0) return null;

            const errorList = Object.entries(projectErrors)
              .map(([field, error]) => `- ${field}: ${error}`)
              .join("\n");

            return `Project ${index + 1}:\n${errorList}`;
          })
          .filter(Boolean)
          .join("\n\n");

        showAlert(`Please fix the following errors:\n\n${errorMessages}`);
        return;
      }

      const applicationId = localStorage.getItem("applicationId");
      if (!applicationId) {
        showAlert("No active application found");
        return;
      }

      // Handle updates and creates separately
      const projectsToUpdate = [];
      const projectsToCreate = [];

      for (const project of projects) {
        const projectData = {
          application: applicationId,
          organization: project.organization || "#",
          research_domain: project.research_domain || "#",
          project_name: project.project_name || "#",
          role: project.role || "#",
          start_date: project.start_date,
          end_date: project.end_date,
          background: project.background || "",
          goal: project.goal || "",
          challenge: project.challenge || "",
          solution: project.solution || "",
          impact: project.impact || "",
          comments: project.comments || "",
          funding_source: project.is_published ? project.funding_source : "#",
          funding_source_country: project.is_published
            ? project.funding_source_country
            : "#",
          funding_amount: project.is_published
            ? project.funding_amount
            : "0.00",
          is_published: project.is_published || false,
          published_date: project.is_published
            ? project.published_date
            : new Date().toISOString().split("T")[0],
          published_url: project.is_published
            ? project.published_url
            : "www.example.com",
          published_journal: project.is_published
            ? project.published_journal
            : "#",
          citation_count: project.is_published ? project.citation_count : "0",
        };

        if (project.id && !deletedProjects.has(project.id)) {
          projectsToUpdate.push({ ...projectData, id: project.id });
        } else if (!project.id) {
          projectsToCreate.push(projectData);
        }
      }

      // Handle updates
      if (projectsToUpdate.length > 0) {
        await protectedApi.patch("/api/academicprojects/", projectsToUpdate, {
          headers: {
            "Content-Type": "application/json",
            "X-Application-ID": applicationId,
          },
        });
      }

      // Handle creates
      for (const projectData of projectsToCreate) {
        await protectedApi.post("/api/academicprojects/", projectData, {
          headers: {
            "Content-Type": "application/json",
            "X-Application-ID": applicationId,
          },
        });
      }

      if (action === "continue") {
        navigate("/employment-info");
      } else {
        showAlert("Projects saved successfully!");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      if (handleAuthError(error, navigate)) return;
      showAlert("Error saving projects. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteProject = async (index) => {
    const projectToDelete = projects[index];

    if (projectToDelete.id) {
      try {
        await protectedApi.delete(
          `/api/academicprojects/${projectToDelete.id}/`
        );
        setProjects(projects.filter((_, idx) => idx !== index));
        setOpenProjects(openProjects.filter((_, idx) => idx !== index));
        setErrors(errors.filter((_, idx) => idx !== index));
      } catch (error) {
        console.error("Error deleting project:", error);
        if (handleAuthError(error, navigate)) return;
        showAlert("Failed to delete project");
      }
    } else {
      setProjects(projects.filter((_, idx) => idx !== index));
      setOpenProjects(openProjects.filter((_, idx) => idx !== index));
      setErrors(errors.filter((_, idx) => idx !== index));
    }
  };

  const addNewProject = () => {
    setProjects([...projects, { ...initialProjectState }]);
    setOpenProjects([...openProjects, true]);
    setErrors([...errors, {}]);
  };

  const toggleProject = (index) => {
    setOpenProjects((prev) => {
      const newState = [...prev];
      newState[index] = !newState[index];
      return newState;
    });
  };

  const handleSave = (e) => handleFormSubmit(e, "save");
  const handleContinue = (e) => handleFormSubmit(e, "continue");

  return (
    <div className={styles.container}>
      <Header2 />
      <div className={styles.pageLayout}>
        <Sidebar />
        <div className={styles.createProfile}>
          <div className={styles.wrapper}>
            <h1 className={styles.createYourProfile}>Create Your Profile</h1>
            {/* <h2 className={styles.sectionTitle}>
          Scientific Project and Publications
        </h2> */}

            <form onSubmit={handleContinue}>
              <div className={styles.formContainer}>
                {projects.map((project, index) => (
                  <div key={index} className={styles.formSection}>
                    <div className={styles.projectHeader}>
                      <div className={styles.projectTitleWrapper}>
                        <h2 className={styles.projectTitle}>
                          {` Educational / Scientific Project ${index + 1}`}
                        </h2>
                      </div>
                      <div className={styles.headerActions}>
                        <svg
                          className={styles.deleteIcon}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteProject(index);
                          }}
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                        >
                          <path
                            d="M6 7V18C6 19.1046 6.89543 20 8 20H16C17.1046 20 18 19.1046 18 18V7M6 7H5M6 7H8M18 7H19M18 7H16M10 11V16M14 11V16M8 7V5C8 3.89543 8.89543 3 10 3H14C15.1046 3 16 3.89543 16 5V7M8 7H16"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>

                        <svg
                          className={`${styles.dropdownIcon} ${
                            openProjects[index] ? styles.open : ""
                          }`}
                          onClick={() => toggleProject(index)}
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                        >
                          <path
                            d="M6 9l6 6 6-6"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    </div>

                    <div
                      className={`${styles.projectContent} ${
                        openProjects[index] ? styles.open : ""
                      }`}
                    >
                      {index === 0 && (
                        <div className={styles.sectionHeader}>
                          <Stepper
                            currentStep={3}
                            totalSteps={7}
                            progressWidth={57}
                          />
                        </div>
                      )}

                      <div className={styles.formRow}>
                        <div className={styles.inputWrapper}>
                          <input
                            type="text"
                            name="organization"
                            placeholder="Organization Name"
                            value={project.organization}
                            onChange={(e) => handleChange(e, index)}
                            className={styles.inputField}
                          />
                          {errors[index]?.organization && (
                            <span className={styles.errorMessage}>
                              {errors[index].organization}
                            </span>
                          )}
                        </div>
                        <div className={styles.inputWrapper}>
                          <CustomDropdown
                            name="research_domain"
                            value={project.research_domain}
                            onChange={(e) => handleChange(e, index)}
                            options={RESEARCH_DOMAINS}
                            placeholder="Research Domain"
                            className={`${
                              errors[index]?.research_domain
                                ? styles.errorInput
                                : ""
                            }`}
                          />
                          {errors[index]?.research_domain && (
                            <span className={styles.errorMessage}>
                              {errors[index].research_domain}
                            </span>
                          )}
                        </div>
                      </div>

                      <div className={styles.formRow}>
                        <div className={styles.inputWrapper}>
                          <input
                            type="text"
                            name="project_name"
                            placeholder="Project Name"
                            value={project.project_name}
                            onChange={(e) => handleChange(e, index)}
                            className={`${styles.inputField} ${
                              errors[index]?.project_name
                                ? styles.errorInput
                                : ""
                            }`}
                          />
                          {errors[index]?.project_name && (
                            <span className={styles.errorMessage}>
                              {errors[index].project_name}
                            </span>
                          )}
                        </div>
                        <div className={styles.inputWrapper}>
                          <input
                            type="text"
                            name="role"
                            placeholder="Role"
                            value={project.role}
                            onChange={(e) => handleChange(e, index)}
                            className={`${styles.inputField} ${
                              errors[index]?.role ? styles.errorInput : ""
                            }`}
                          />
                          {errors[index]?.role && (
                            <span className={styles.errorMessage}>
                              {errors[index].role}
                            </span>
                          )}
                        </div>
                      </div>

                      <div className={styles.formRow}>
                        <div className={styles.inputWrapper}>
                          <label className={styles.inputLabel}>
                            Start Date
                          </label>
                          <input
                            type="date"
                            name="start_date"
                            value={project.start_date}
                            onChange={(e) => handleChange(e, index)}
                            className={`${styles.inputField} ${
                              errors[index]?.start_date ? styles.errorInput : ""
                            }`}
                          />
                          {errors[index]?.start_date && (
                            <span className={styles.errorMessage}>
                              {errors[index].start_date}
                            </span>
                          )}
                        </div>
                        <div className={styles.inputWrapper}>
                          <label className={styles.inputLabel}>End Date</label>
                          <input
                            type="date"
                            name="end_date"
                            value={project.end_date}
                            onChange={(e) => handleChange(e, index)}
                            className={`${styles.inputField} ${
                              errors[index]?.end_date ? styles.errorInput : ""
                            }`}
                          />
                          {errors[index]?.end_date && (
                            <span className={styles.errorMessage}>
                              {errors[index].end_date}
                            </span>
                          )}
                        </div>
                      </div>

                      <div className={styles.inputWrapper}>
                        <textarea
                          name="background"
                          placeholder="Project Background"
                          value={project.background}
                          onChange={(e) => handleChange(e, index)}
                          className={`${styles.textArea} ${
                            errors[index]?.background ? styles.errorInput : ""
                          }`}
                        />
                        {errors[index]?.background && (
                          <span className={styles.errorMessage}>
                            {errors[index].background}
                          </span>
                        )}
                      </div>

                      <div className={styles.textAreaRow}>
                        <div className={styles.inputWrapper}>
                          <textarea
                            name="goal"
                            placeholder="Project Goal"
                            value={project.goal}
                            onChange={(e) => handleChange(e, index)}
                            className={`${styles.textArea} ${
                              errors[index]?.goal ? styles.errorInput : ""
                            }`}
                          />
                          {errors[index]?.goal && (
                            <span className={styles.errorMessage}>
                              {errors[index].goal}
                            </span>
                          )}
                        </div>
                        <div className={styles.inputWrapper}>
                          <textarea
                            name="challenge"
                            placeholder="Project Challenge"
                            value={project.challenge}
                            onChange={(e) => handleChange(e, index)}
                            className={`${styles.textArea} ${
                              errors[index]?.challenge ? styles.errorInput : ""
                            }`}
                          />
                          {errors[index]?.challenge && (
                            <span className={styles.errorMessage}>
                              {errors[index].challenge}
                            </span>
                          )}
                        </div>
                      </div>

                      <div className={styles.textAreaRow}>
                        <div className={styles.inputWrapper}>
                          <textarea
                            name="solution"
                            placeholder="Project Solution"
                            value={project.solution}
                            onChange={(e) => handleChange(e, index)}
                            className={`${styles.textArea} ${
                              errors[index]?.solution ? styles.errorInput : ""
                            }`}
                          />
                          {errors[index]?.solution && (
                            <span className={styles.errorMessage}>
                              {errors[index].solution}
                            </span>
                          )}
                        </div>
                        <div className={styles.inputWrapper}>
                          <textarea
                            name="impact"
                            placeholder="Project Impact"
                            value={project.impact}
                            onChange={(e) => handleChange(e, index)}
                            className={`${styles.textArea} ${
                              errors[index]?.impact ? styles.errorInput : ""
                            }`}
                          />
                          {errors[index]?.impact && (
                            <span className={styles.errorMessage}>
                              {errors[index].impact}
                            </span>
                          )}
                        </div>
                      </div>

                      <div className={styles.subSection}>
                        <div className={styles.subSectionHeader}>
                          <h4 className={styles.subSectionTitle}>
                            Publication Information
                          </h4>
                        </div>

                        <div className={styles.formRow}>
                          <div className={styles.inputWrapper}>
                            <input
                              type="url"
                              name="published_url"
                              placeholder="Publication URL"
                              value={project.published_url}
                              onChange={(e) => handleChange(e, index)}
                              className={styles.inputField}
                            />
                            {errors[index]?.published_url && (
                              <span className={styles.errorMessage}>
                                {errors[index].published_url}
                              </span>
                            )}
                          </div>
                          <div className={styles.inputWrapper}>
                            <input
                              type="text"
                              name="published_journal"
                              placeholder="Publication Journal"
                              value={project.published_journal}
                              onChange={(e) => handleChange(e, index)}
                              className={styles.inputField}
                            />
                            {errors[index]?.published_journal && (
                              <span className={styles.errorMessage}>
                                {errors[index].published_journal}
                              </span>
                            )}
                          </div>
                          <div className={styles.inputWrapper}>
                            <input
                              type="number"
                              name="citation_count"
                              placeholder="Citation Count"
                              value={project.citation_count}
                              onChange={(e) => handleChange(e, index)}
                              className={styles.inputField}
                            />
                            {errors[index]?.citation_count && (
                              <span className={styles.errorMessage}>
                                {errors[index].citation_count}
                              </span>
                            )}
                          </div>
                        </div>

                        {/* <div className={styles.formRow}> */}

                        {/* <div className={styles.inputWrapper}>
                        <input
                          type="text"
                          name="impactFactor"
                          placeholder="Impact Factor"
                          value={project.impactFactor}
                          onChange={(e) => handleChange(e, index)}
                          className={styles.inputField}
                        />
                      </div> */}
                        {/* </div> */}

                        <div className={styles.formRow}>
                          <div className={styles.inputWrapper}>
                            <label className={styles.inputLabel}>
                              Published Date
                            </label>
                            <input
                              type="date"
                              name="published_date"
                              value={project.published_date}
                              onChange={(e) => handleChange(e, index)}
                              className={styles.inputField}
                            />
                            {errors[index]?.published_date && (
                              <span className={styles.errorMessage}>
                                {errors[index].published_date}
                              </span>
                            )}
                          </div>
                          <div className={styles.inputWrapper}>
                            <label className={styles.inputLabel}>&nbsp;</label>
                            <div className={styles.checkboxField}>
                              <input
                                type="checkbox"
                                id={`isPublished-${index}`}
                                name="is_published"
                                checked={project.is_published}
                                onChange={(e) => handleChange(e, index)}
                                className={styles.checkbox}
                              />
                              <label
                                htmlFor={`isPublished-${index}`}
                                className={styles.checkboxLabel}
                              >
                                Already Published
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className={styles.formRow}>
                          <div className={styles.inputWrapper}>
                            <input
                              type="text"
                              name="funding_source"
                              placeholder="Funding Source"
                              value={project.funding_source}
                              onChange={(e) => handleChange(e, index)}
                              className={`${styles.inputField} ${
                                errors[index]?.funding_source
                                  ? styles.errorInput
                                  : ""
                              }`}
                            />
                            {errors[index]?.funding_source && (
                              <span className={styles.errorMessage}>
                                {errors[index].funding_source}
                              </span>
                            )}
                          </div>
                          <div className={styles.inputWrapper}>
                            <input
                              type="text"
                              name="funding_source_country"
                              placeholder="Funding Source Country"
                              value={project.funding_source_country}
                              onChange={(e) => handleChange(e, index)}
                              className={`${styles.inputField} ${
                                errors[index]?.funding_source_country
                                  ? styles.errorInput
                                  : ""
                              }`}
                            />
                            {errors[index]?.funding_source_country && (
                              <span className={styles.errorMessage}>
                                {errors[index].funding_source_country}
                              </span>
                            )}
                          </div>
                          <div className={styles.inputWrapper}>
                            <input
                              type="number"
                              name="funding_amount"
                              placeholder="Funding Amount"
                              value={project.funding_amount}
                              onChange={(e) => handleChange(e, index)}
                              className={`${styles.inputField} ${
                                errors[index]?.funding_amount
                                  ? styles.errorInput
                                  : ""
                              }`}
                            />
                            {errors[index]?.funding_amount && (
                              <span className={styles.errorMessage}>
                                {errors[index].funding_amount}
                              </span>
                            )}
                          </div>
                        </div>

                        <div className={styles.formRow}>
                          <div className={styles.uploadField}>
                            <label className={styles.uploadLabel}>
                              <span>Upload Supporting Documents</span>
                              <span className={styles.fileTypes}>(.pdf)</span>
                              <input
                                type="file"
                                accept=".pdf"
                                onChange={(e) => handleFileUpload(e, index)}
                                className={styles.fileInput}
                              />
                            </label>

                            {/* Show uploaded file below if exists */}
                            {project.essay && (
                              <div className={styles.uploadedFiles}>
                                <div className={styles.fileItem}>
                                  <span className={styles.fileName}>
                                    {project.essayName}
                                  </span>
                                  <button
                                    type="button"
                                    onClick={() => handleRemoveFile(index)}
                                    className={styles.removeFile}
                                    aria-label="Remove file"
                                  >
                                    ×
                                  </button>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}

                <div className={styles.formActions}>
                  <button
                    type="button"
                    className={styles.secondaryButton}
                    onClick={addNewProject}
                  >
                    Add Another Project
                  </button>
                  <div className={styles.navButtons}>
                    <Button
                      variant="secondary"
                      onClick={usePreviousNavigation()}
                    >
                      Previous
                    </Button>
                    <Button variant="secondary" onClick={handleSave}>
                      Save
                    </Button>
                    <Button variant="primary" onClick={handleContinue}>
                      Continue
                    </Button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ScientificProjectPage;
