import React, { useState, useEffect } from "react";
import styles from "./ProjectInfo.module.css";
import { useNavigate } from "react-router-dom";
import Sidebar from "../common/Sidebar";
import Button from "../common/Button";
import Stepper from "../common/ProgressBar.js";
import { protectedApi, handleAuthError } from "../../services/api.js";
import Header2 from "../common/Header2.js";
import { usePreviousNavigation } from "../../services/handlePrevious";
import { getApplicationId } from "../../services/crudHelpers.js";
import { useAlert } from "../common/AlertContext.js";

// Add this helper function to convert file to base64
const fileToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result.split(",")[1]); // Remove data:application/pdf;base64, prefix
    reader.onerror = (error) => reject(error);
  });
};

function ProjectInfoPage() {
  const navigate = useNavigate();
  const { showAlert } = useAlert();

  const [projects, setProjects] = useState([
    {
      organization: "",
      department: "",
      job_title: "",
      role: "",
      project_name: "",
      start_date: "",
      end_date: "",
      background: "",
      goal: "",
      challenge: "",
      solution: "",
      impact: "",
    },
  ]);

  const [errors, setErrors] = useState([]);
  const [fileName, setFileName] = useState(""); // Add this state
  const [files, setFiles] = useState([]);
  const [fileNames, setFileNames] = useState([]);
  const [openProjects, setOpenProjects] = useState([true]);
  const [deletedProjects, setDeletedProjects] = useState(new Set());

  // useEffect(() => {
  //   const fetchExistingApplication = async () => {
  //     try {
  //       const response = await protectedApi.get("/api/professionalprojects/");
  //       console.log("[GET] Professional Projects response:", response.data);

  //       if (
  //         response.data &&
  //         response.data.results &&
  //         response.data.results.length > 0
  //       ) {
  //         const currentApplication =
  //           response.data.results[response.data.results.length - 1];
  //         console.log(
  //           "[GET] Current Professional Projects data:",
  //           currentApplication
  //         );

  //         // Store application ID
  //         localStorage.setItem("applicationId", currentApplication.id);

  //         // Pre-fill project data if exists
  //         if (
  //           currentApplication.projects &&
  //           currentApplication.projects.length > 0
  //         ) {
  //           const projectData = currentApplication.projects.map((project) => ({
  //             organization: project.organization || "",
  //             department: project.department || "",
  //             start_date: project.start_date || "",
  //             end_date: project.end_date || "",
  //             job_title: project.job_title || "",
  //             project_name: project.project_name || "",
  //             role: project.role || "",
  //             background: project.background || "",
  //             goal: project.goal || "",
  //             challenge: project.challenge || "",
  //             solution: project.solution || "",
  //             impact: project.impact || "",
  //             file: null,
  //             fileName: project.file ? project.file.split("/").pop() : "",
  //           }));

  //           setProjects(projectData);
  //           setOpenProjects(new Array(projectData.length).fill(true));
  //           setErrors(new Array(projectData.length).fill({}));
  //         }
  //       }
  //     } catch (error) {
  //       console.error("[GET] Error fetching application:", error);
  //     }
  //   };

  //   fetchExistingApplication();
  // }, []);
  const handleChange = (e, index) => {
    const { name, value } = e.target;
    // console.log(
    //   `[CHANGE] Updating ${name} at index ${index} with value:`,
    //   value
    // );

    setProjects((prevProjects) => {
      const updatedProjects = [...prevProjects];
      updatedProjects[index] = {
        ...updatedProjects[index],
        [name]: value,
      };
      return updatedProjects;
    });
  };

  const validateForm = () => {
    const newErrors = projects.map((project) => {
      const projectErrors = {};

      const requiredFields = [
        "organization",
        "department",
        "job_title",
        "role",
        "project_name",
        "start_date",
        "end_date",
        "background",
        "goal",
        "challenge",
        "solution",
        "impact",
      ];

      requiredFields.forEach((field) => {
        const value = project[field];
        // Check if value is empty, just whitespace, or a single character
        if (!value || (typeof value === "string" && value.trim().length <= 1)) {
          const formattedField = field
            .split("_")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ");
          projectErrors[
            field
          ] = `${formattedField} is required and must be meaningful`;
          console.log(`Validation failed for ${field}: "${value}"`);
        }
      });

      // Log the entire project data for debugging
      console.log("Validating project data:", project);

      return projectErrors;
    });

    console.log("Validation errors:", newErrors);
    setErrors(newErrors);
    return newErrors.every(
      (projectErrors) => Object.keys(projectErrors).length === 0
    );
  };

  // Todo: login -> application selection : create a new application || edit exsiting application
  // Todo: edit exsiting application ->
  // Todo: Call Get API to get application -> applications.ids
  // Todo: select application.id
  // Todo: if application.id exists, fill the application and 'SAVE' and 'CONTINUE' button will call put api
  // Todo: if application.id does not exist, redirect to  create application

  const handleFormSubmit = async (e, action = "save") => {
    e.preventDefault();

    try {
      const applicationId = localStorage.getItem("applicationId");
      if (!applicationId) {
        showAlert(
          "No active application found. Please create a personal profile first."
        );
        navigate("/personal-info");
        return;
      }

      if (!validateForm()) {
        showAlert("Please fill in all required fields correctly");
        return;
      }

      // Filter out active projects (not deleted)
      const activeProjects = projects.filter(
        (project) => !deletedProjects.has(project.id)
      );

      // Prepare projects for submission
      const projectsToSubmit = await Promise.all(
        activeProjects.map(async (project) => {
          const fileIndex = projects.indexOf(project);
          let fileData = null;

          if (files[fileIndex]) {
            try {
              const base64Data = await fileToBase64(files[fileIndex]);
              fileData = {
                filename: files[fileIndex].name,
                content_type: files[fileIndex].type,
                base64_content: base64Data,
              };
            } catch (error) {
              console.error(`Error converting file to base64:`, error);
            }
          }

          // Create the base project data
          const projectData = {
            organization: project.organization,
            department: project.department,
            job_title: project.job_title,
            role: project.role,
            project_name: project.project_name,
            start_date: project.start_date,
            end_date: project.end_date,
            background: project.background,
            goal: project.goal,
            challenge: project.challenge,
            solution: project.solution,
            impact: project.impact,
            supporting_docs: fileData || project.supporting_docs,
            application: parseInt(applicationId), // Ensure applicationId is included
          };

          // Include ID if it exists (for updates)
          if (project.id) {
            projectData.id = project.id;
          }

          return projectData;
        })
      );

      // Separate projects into updates and creates
      const projectsToUpdate = projectsToSubmit.filter((p) => p.id);
      const projectsToCreate = projectsToSubmit.filter((p) => !p.id);

      // Handle updates
      if (projectsToUpdate.length > 0) {
        const updateResponse = await protectedApi.patch(
          `/api/professionalprojects/`,
          projectsToUpdate,
          {
            headers: {
              "Content-Type": "application/json",
              "X-Application-ID": applicationId,
            },
          }
        );
        console.log("Update response:", updateResponse.data);
      }

      // Handle creates
      if (projectsToCreate.length > 0) {
        const createResponse = await protectedApi.post(
          "/api/professionalprojects/",
          projectsToCreate,
          {
            headers: {
              "Content-Type": "application/json",
              "X-Application-ID": applicationId,
              "X-CSRFToken":
                document.cookie.match(/csrftoken=([\w-]+)/)?.[1] || "",
            },
          }
        );
        console.log("Create response:", createResponse.data);
      }

      // Handle deletions
      if (deletedProjects.size > 0) {
        const deletePromises = Array.from(deletedProjects).map((projectId) =>
          protectedApi.delete(`/api/professionalprojects/${projectId}/`, {
            headers: {
              "Content-Type": "application/json",
              "X-Application-ID": applicationId,
            },
          })
        );
        await Promise.all(deletePromises);
      }

      if (action === "continue") {
        navigate("/evidence");
      } else {
        showAlert("Projects saved successfully!");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      if (error.response?.data) {
        const errorMessage = Array.isArray(error.response.data)
          ? error.response.data.map((err) => err.error).join("\n")
          : error.response.data.error || "Unknown error";
        showAlert(`Error: ${errorMessage}`);
        if (handleAuthError(error, navigate)) return;
      } else {
        showAlert(error.message || "Error saving projects");
      }
    }
  };

  // Helper function to handle individual project updates
  const handleProjectUpdate = async (project, index) => {
    try {
      const applicationId = localStorage.getItem("applicationId");
      if (!applicationId) {
        throw new Error("No active application found");
      }

      const fileData = files[index]
        ? await prepareFileData(files[index])
        : null;
      const projectData = prepareProjectData(project, fileData);

      if (project.id) {
        await protectedApi.patch(
          `/api/professionalprojects/${project.id}/`,
          projectData,
          {
            headers: {
              "Content-Type": "application/json",
              "X-Application-ID": applicationId,
            },
          }
        );
      } else {
        const response = await protectedApi.post(
          "/api/professionalprojects/",
          {
            ...projectData,
            application: applicationId,
          },
          {
            headers: {
              "Content-Type": "application/json",
              "X-Application-ID": applicationId,
            },
          }
        );

        // Update local state with new project ID
        setProjects((prevProjects) => {
          const updatedProjects = [...prevProjects];
          updatedProjects[index] = { ...project, id: response.data.id };
          return updatedProjects;
        });
      }

      return true;
    } catch (error) {
      console.error("Error updating project:", error);
      throw error;
    }
  };

  // Helper functions for data preparation
  const prepareFileData = async (file) => {
    if (!file) return null;
    const base64Data = await fileToBase64(file);
    return {
      filename: file.name,
      content_type: file.type,
      base64_content: base64Data,
    };
  };

  const prepareProjectData = (project, fileData) => ({
    organization: project.organization,
    department: project.department,
    job_title: project.job_title,
    role: project.role,
    project_name: project.project_name,
    start_date: project.start_date,
    end_date: project.end_date,
    background: project.background,
    goal: project.goal,
    challenge: project.challenge,
    solution: project.solution,
    impact: project.impact,
    supporting_docs: fileData || project.supporting_docs,
  });

  const handleSave = (e) => handleFormSubmit(e, "save");
  const handleContinue = (e) => handleFormSubmit(e, "continue");

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);

    // Update file names for display
    const newFileNames = selectedFiles.map((file) => file.name);
    setFileNames((prevNames) => [...prevNames, ...newFileNames]);
  };

  const removeFile = (indexToRemove) => {
    setFiles((prevFiles) =>
      prevFiles.filter((_, index) => index !== indexToRemove)
    );
    setFileNames((prevNames) =>
      prevNames.filter((_, index) => index !== indexToRemove)
    );
  };

  const addNewProject = () => {
    setProjects((prevProjects) => [
      ...prevProjects,
      {
        organization: "",
        department: "",
        job_title: "",
        role: "",
        project_name: "",
        start_date: "",
        end_date: "",
        background: "",
        goal: "",
        challenge: "",
        solution: "",
        impact: "",
        file: null,
        fileName: "",
      },
    ]);
    setOpenProjects((prevProjects) => [...prevProjects, true]);
    setErrors((prevErrors) => [...prevErrors, {}]);
  };

  const toggleProject = (index) => {
    setOpenProjects((prev) => {
      const newState = [...prev];
      newState[index] = !newState[index];
      return newState;
    });
  };

  const handleDeleteProject = async (index) => {
    try {
      const projectToDelete = projects[index];

      // Don't allow deletion if it's the last project
      if (projects.length <= 1) {
        showAlert("You must have at least one project.");
        return;
      }

      // Make DELETE request with the project ID
      if (projectToDelete.id) {
        await protectedApi.delete(
          `/api/professionalprojects/${projectToDelete.id}/`
        );
        setDeletedProjects((prev) => new Set([...prev, projectToDelete.id]));
      }

      // Update local state
      const updatedProjects = [...projects];
      updatedProjects.splice(index, 1);
      setProjects(updatedProjects);
    } catch (error) {
      console.error("Error deleting project:", error);
      showAlert("Failed to delete project. Please try again.");
    }
  };

  // Add this validation function
  const validateProjectData = (project) => {
    const requiredFields = [
      "organization",
      "department",
      "job_title",
      "role",
      "project_name",
      "start_date",
      "end_date",
      "background",
      "goal",
      "challenge",
      "solution",
      "impact",
    ];

    const missingFields = requiredFields.filter(
      (field) => !project[field] || project[field].trim() === ""
    );

    if (missingFields.length > 0) {
      console.error("Missing required fields:", missingFields);
      return false;
    }
    return true;
  };

  // // Add this check in your handleFormSubmit
  // if (!projects.every(validateProjectData)) {
  //   alert("Please ensure all required fields are filled out properly");
  //   return;
  // }

  useEffect(() => {
    const fetchExistingApplication = async () => {
      try {
        const applicationId = await getApplicationId();
        if (!applicationId) {
          console.log("[GET] No application ID found");
          navigate("/personal-info");
          return;
        }
        console.log("[GET] Application ID:", applicationId);

        console.log("[GET] Fetching projects for application:", applicationId);
        const response = await protectedApi.get("/api/professionalprojects/", {
          headers: {
            "X-Application-ID": applicationId,
          },
        });
        console.log("[GET] Professional Projects response:", response.data);

        if (response.data?.results?.length > 0) {
          // Filter active projects (not deleted)
          const activeProjects = response.data.results.filter(
            (project) => !project.is_deleted
          );
          console.log("[GET] Active projects:", activeProjects);

          if (activeProjects.length > 0) {
            // Map projects to form structure
            const projectData = activeProjects.map((project) => ({
              id: project.id,
              organization: project.organization || "",
              department: project.department || "",
              job_title: project.job_title || "",
              role: project.role || "",
              project_name: project.project_name || "",
              start_date: project.start_date || "",
              end_date: project.end_date || "",
              background: project.background || "",
              goal: project.goal || "",
              challenge: project.challenge || "",
              solution: project.solution || "",
              impact: project.impact || "",
              supporting_docs: project.supporting_docs || null,
            }));

            console.log("[GET] Mapped project data:", projectData);
            setProjects(projectData);
            setOpenProjects(new Array(projectData.length).fill(true));
            setErrors(new Array(projectData.length).fill({}));

            // Handle files if they exist
            const existingFiles = projectData
              .map((project) => project.supporting_docs)
              .filter(Boolean);

            console.log("[GET] Existing files:", existingFiles);
            setFileNames(existingFiles.map((file) => file.split("/").pop()));
          }
        } else {
          console.log("[GET] No existing projects found");
        }
      } catch (error) {
        console.error("[GET] Error fetching projects:", error);
        if (error.response) {
          console.error("[GET] Error response data:", error.response.data);
        }
      }
    };

    fetchExistingApplication();
  }, [navigate]); // Empty dependency array means this runs once on mount

  // // Add logging to state updates
  // useEffect(() => {
  //   console.log("[STATE] Current projects:", projects);
  // }, [projects]);

  // useEffect(() => {
  //   console.log("[STATE] Current files:", files);
  //   console.log("[STATE] Current fileNames:", fileNames);
  // }, [files, fileNames]);

  return (
    <div className={styles.container}>
      <Header2 />
      <div className={styles.pageLayout}>
        <Sidebar />
        <div className={styles.createProfile}>
          <div className={styles.wrapper}>
            <h1 className={styles.createYourProfile}>Create Your Profile</h1>
            <form onSubmit={handleContinue}>
              <div className={styles.formContainer}>
                {projects.map((project, index) => (
                  <div key={index} className={styles.formSection}>
                    <div className={styles.projectHeader}>
                      <h2 className={styles.projectTitle}>
                        {`Employment Project ${project.project_name}` ||
                          `Employment Project ${index + 1}`}
                      </h2>
                      <div className={styles.headerActions}>
                        {index === 0 && (
                          <svg
                            className={styles.deleteIcon}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleDeleteProject(index);
                            }}
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                          >
                            <path
                              d="M6 7V18C6 19.1046 6.89543 20 8 20H16C17.1046 20 18 19.1046 18 18V7M6 7H5M6 7H8M18 7H19M18 7H16M10 11V16M14 11V16M8 7V5C8 3.89543 8.89543 3 10 3H14C15.1046 3 16 3.89543 16 5V7M8 7H16"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        )}
                        <svg
                          className={`${styles.dropdownIcon} ${
                            openProjects[index] ? styles.open : ""
                          }`}
                          onClick={() => toggleProject(index)}
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                        >
                          <path
                            d="M6 9l6 6 6-6"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    </div>

                    <div
                      className={`${styles.projectContent} ${
                        openProjects[index] ? styles.open : ""
                      }`}
                    >
                      {index === 0 && (
                        <div className={styles.sectionHeader}>
                          <Stepper
                            currentStep={5}
                            totalSteps={7}
                            progressWidth={28}
                          />
                        </div>
                      )}

                      {/* Original Input Fields */}
                      <div className={styles.formRow}>
                        <div className={styles.inputWrapper}>
                          <input
                            type="text"
                            name="organization"
                            placeholder="Organization"
                            value={project.organization}
                            onChange={(e) => handleChange(e, index)}
                            className={`${styles.inputField} ${
                              errors[index]?.organization
                                ? styles.errorInput
                                : ""
                            }`}
                            required
                          />
                          {errors[index]?.organization && (
                            <span className={styles.errorMessage}>
                              {errors[index].organization}
                            </span>
                          )}
                        </div>
                        <div className={styles.inputWrapper}>
                          <input
                            type="text"
                            name="department"
                            placeholder="Department Name"
                            value={project.department}
                            onChange={(e) => handleChange(e, index)}
                            className={`${styles.inputField} ${
                              errors[index]?.department ? styles.errorInput : ""
                            }`}
                            required
                          />
                          {errors[index]?.department && (
                            <span className={styles.errorMessage}>
                              {errors[index].department}
                            </span>
                          )}
                        </div>
                      </div>

                      <div className={styles.formRow}>
                        <div className={styles.inputWrapper}>
                          <label className={styles.inputLabel}>
                            Start Date
                          </label>
                          <input
                            type="date"
                            name="start_date"
                            value={project.start_date}
                            onChange={(e) => handleChange(e, index)}
                            className={`${styles.inputField} ${
                              errors[index]?.start_date ? styles.errorInput : ""
                            }`}
                            required
                          />
                          {errors[index]?.start_date && (
                            <span className={styles.errorMessage}>
                              {errors[index].start_date}
                            </span>
                          )}
                        </div>
                        <div className={styles.inputWrapper}>
                          <label className={styles.inputLabel}>End Date</label>

                          <input
                            type="date"
                            name="end_date"
                            placeholder="End Date"
                            value={project.end_date}
                            onChange={(e) => handleChange(e, index)}
                            className={`${styles.inputField} ${
                              errors[index]?.end_date ? styles.errorInput : ""
                            }`}
                            required
                          />
                          {errors[index]?.end_date && (
                            <span className={styles.errorMessage}>
                              {errors[index].end_date}
                            </span>
                          )}
                        </div>
                      </div>

                      <div className={styles.formRow}>
                        <div className={styles.inputWrapper}>
                          <input
                            type="text"
                            name="job_title"
                            placeholder="Job Title"
                            value={project.job_title}
                            onChange={(e) => handleChange(e, index)}
                            className={`${styles.inputField} ${
                              errors[index]?.job_title ? styles.errorInput : ""
                            }`}
                            required
                          />
                          {errors[index]?.job_title && (
                            <span className={styles.errorMessage}>
                              {errors[index].job_title}
                            </span>
                          )}
                        </div>
                        <div className={styles.inputWrapper}>
                          <input
                            type="text"
                            name="project_name"
                            placeholder="Project Name"
                            value={project.project_name}
                            onChange={(e) => handleChange(e, index)}
                            className={`${styles.inputField} ${
                              errors[index]?.project_name
                                ? styles.errorInput
                                : ""
                            }`}
                            required
                          />
                          {errors[index]?.project_name && (
                            <span className={styles.errorMessage}>
                              {errors[index].project_name}
                            </span>
                          )}
                        </div>
                      </div>

                      <div className={styles.formRow}>
                        <div className={styles.inputWrapper}>
                          <input
                            type="text"
                            name="role"
                            placeholder="Role"
                            value={project.role}
                            onChange={(e) => handleChange(e, index)}
                            className={`${styles.inputField} ${
                              errors[index]?.role ? styles.errorInput : ""
                            }`}
                            required
                          />
                          {errors[index]?.role && (
                            <span className={styles.errorMessage}>
                              {errors[index].role}
                            </span>
                          )}
                        </div>
                      </div>

                      {/* Background Textarea - Full Width */}
                      <div className={styles.inputWrapper}>
                        <textarea
                          name="background"
                          placeholder="Background"
                          value={project.background}
                          onChange={(e) => handleChange(e, index)}
                          className={`${styles.textArea} ${
                            errors[index]?.background ? styles.errorInput : ""
                          }`}
                          required
                        />
                        {errors[index]?.background && (
                          <span className={styles.errorMessage}>
                            {errors[index].background}
                          </span>
                        )}
                      </div>

                      {/* Other Textareas - Two per Row */}
                      <div className={styles.textAreaRow}>
                        <div className={styles.inputWrapper}>
                          <textarea
                            name="goal"
                            placeholder="Goal"
                            value={project.goal}
                            onChange={(e) => handleChange(e, index)}
                            className={`${styles.textArea} ${
                              errors[index]?.goal ? styles.errorInput : ""
                            }`}
                            required
                          />
                          {errors[index]?.goal && (
                            <span className={styles.errorMessage}>
                              {errors[index].goal}
                            </span>
                          )}
                        </div>
                        <div className={styles.inputWrapper}>
                          <textarea
                            name="challenge"
                            placeholder="Challenge"
                            value={project.challenge}
                            onChange={(e) => handleChange(e, index)}
                            className={`${styles.textArea} ${
                              errors[index]?.challenge ? styles.errorInput : ""
                            }`}
                            required
                          />
                          {errors[index]?.challenge && (
                            <span className={styles.errorMessage}>
                              {errors[index].challenge}
                            </span>
                          )}
                        </div>
                      </div>

                      <div className={styles.textAreaRow}>
                        <div className={styles.inputWrapper}>
                          <textarea
                            name="solution"
                            placeholder="Solution"
                            value={project.solution}
                            onChange={(e) => handleChange(e, index)}
                            className={`${styles.textArea} ${
                              errors[index]?.solution ? styles.errorInput : ""
                            }`}
                            required
                          />
                          {errors[index]?.solution && (
                            <span className={styles.errorMessage}>
                              {errors[index].solution}
                            </span>
                          )}
                        </div>
                        <div className={styles.inputWrapper}>
                          <textarea
                            name="impact"
                            placeholder="Quantitative and Qualitative Impact"
                            value={project.impact}
                            onChange={(e) => handleChange(e, index)}
                            className={`${styles.textArea} ${
                              errors[index]?.impact ? styles.errorInput : ""
                            }`}
                            required
                          />
                          {errors[index]?.impact && (
                            <span className={styles.errorMessage}>
                              {errors[index].impact}
                            </span>
                          )}
                        </div>
                      </div>

                      <div className={styles.fileUploadContainer}>
                        <input
                          type="file"
                          accept=".pdf"
                          onChange={handleFileChange}
                          className={styles.fileInput}
                          id="fileUpload"
                          multiple
                        />
                        <label
                          htmlFor="fileUpload"
                          className={styles.fileInputLabel}
                        >
                          Upload Supporting Documents (PDF)
                        </label>

                        <div className={styles.uploadedFiles}>
                          {fileNames.map((name, fileIndex) => (
                            <div key={fileIndex} className={styles.fileItem}>
                              <span>{name}</span>
                              <button
                                type="button"
                                onClick={() => removeFile(fileIndex)}
                                className={styles.removeFile}
                                aria-label="Remove file"
                              >
                                ×
                              </button>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}

                {/* Form Actions - Outside the projects loop */}
                <div className={styles.formActions}>
                  <button
                    type="button"
                    className={styles.secondaryButton}
                    onClick={addNewProject}
                  >
                    Add Another Project
                  </button>
                  <div className={styles.navButtons}>
                    <Button
                      variant="secondary"
                      onClick={usePreviousNavigation()}
                    >
                      Previous
                    </Button>
                    <Button variant="secondary" onClick={handleSave}>
                      Save
                    </Button>
                    <Button variant="primary" onClick={handleContinue}>
                      Continue
                    </Button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProjectInfoPage;
