import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./Cart.module.css";
import Header2 from "../common/Header2";
import Sidebar from "../common/Sidebar";
import Button from "../common/Button";
import { protectedApi } from "../../services/api";
import { useAlert } from "../common/AlertContext.js";

// Todo: proposed endeavor * 10??
function Cart({ cart = [], removeFromCart }) {
  const navigate = useNavigate();
  const [cartItems, setCartItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [cartId, setCartId] = useState(null);
  const { showAlert } = useAlert();

  // Fetch cart items from API
  useEffect(() => {
    const fetchCartItems = async () => {
      try {
        const cartResponse = await protectedApi.post("/api/payments/cart/");
        setCartId(cartResponse.data.id);
        console.log("cartId", cartResponse.data.id);

        const itemsResponse = await protectedApi.get(
          `/api/payments/cart/${cartResponse.data.id}/items/`
        );

        // Log the response to see what we're getting
        console.log("API Response:", itemsResponse.data);

        // Directly set the response data as cartItems
        setCartItems(itemsResponse.data);
      } catch (error) {
        console.error("Error fetching cart items:", error);
        setCartItems(null);
      } finally {
        setIsLoading(false);
      }
    };
    fetchCartItems();
  }, []);

  // Debug log to see what cartItems contains
  console.log("Current cartItems:", cartItems);

  // Handle quantity changes
  const updateQuantity = async (itemId, action) => {
    try {
      let method = "POST";
      if (action === "decrease") {
        method = "DELETE";
      }

      const response = await protectedApi.request({
        method: method,
        url: `/api/payments/cart/${cartId}/items/`,
        data: {
          item_id: itemId,
          quantity: action === "increase" ? 1 : -1,
        },
      });
      console.log("response", response);

      // Update local state
      setCartItems((prevState) => {
        const updatedItems = prevState.items
          .map((item) => {
            if (item.item_id === itemId) {
              const newQuantity =
                action === "increase" ? item.quantity + 1 : item.quantity - 1;
              return {
                ...item,
                quantity: newQuantity,
                total_price: newQuantity * item.price,
              };
            }
            return item;
          })
          .filter((item) => item.quantity > 0);

        return {
          ...prevState,
          items: updatedItems,
          total_items:
            action === "increase"
              ? prevState.total_items + 1
              : prevState.total_items - 1,
          total_price: updatedItems.reduce(
            (total, item) => total + item.price * item.quantity,
            0
          ),
        };
      });
    } catch (error) {
      console.error("Error updating quantity:", error);
    }
  };

  const calculateTotal = () => {
    return cartItems.reduce((total, item) => total + item.total_price, 0);
  };

  const handleRemoveItem = async (itemId) => {
    try {
      // Find the current quantity of the item
      const itemToRemove = cartItems.items.find(
        (item) => item.item_id === itemId
      );
      const currentQuantity = itemToRemove.quantity;

      // Call API to remove item with its current quantity
      const response = await protectedApi.request({
        method: "DELETE",
        url: `/api/payments/cart/${cartId}/items/`,
        data: {
          item_id: itemId,
          quantity: currentQuantity, // Send the actual current quantity
        },
      });

      // Check if removal was successful
      if (response.status === 204 || response.status === 200) {
        showAlert("Item removed from cart");

        // Update local state by filtering out the removed item
        setCartItems((prevState) => {
          // Find the item to get its quantity and price for total calculations
          const removedItem = prevState.items.find(
            (item) => item.item_id === itemId
          );

          return {
            ...prevState,
            items: prevState.items.filter((item) => item.item_id !== itemId),
            total_items: prevState.total_items - removedItem.quantity,
            total_price:
              prevState.total_price - removedItem.price * removedItem.quantity,
          };
        });
      }
    } catch (error) {
      console.error("Error removing item:", error);
    }
  };

  const handleCheckout = async () => {
    try {
      console.log("Sending checkout request...");
      // Validate cart has items with positive quantities
      const hasValidItems = cartItems.items.some((item) => item.quantity > 0);
      if (!hasValidItems) {
        showAlert(
          "Your cart is empty or contains invalid quantities. Please add items before checking out."
        );
        return;
      }

      const requestData = {
        redirect_url: `${window.location.origin}/order-confirmation`,
        quantity: cartItems.total_items,
      };

      const response = await protectedApi.post(
        `/api/payments/cart/${cartId}/checkout/`,
        requestData
      );
      console.log("Request:", requestData);
      console.log("Checkout Response:", response);
      localStorage.setItem("orderId", response.data.order_id);

      if (response.data.payment_url) {
        // Create URL object to properly handle parameters
        const paymentUrl = new URL(response.data.payment_url);

        console.log("Redirecting to payment URL:", paymentUrl.toString());
        window.location.href = paymentUrl.toString();
      } else {
        showAlert(
          "There was an error processing your checkout. Please try again."
        );
      }
    } catch (error) {
      console.error("Checkout error details:", {
        message: error.message,
        response: error.response?.data,
        status: error.response?.status,
      });

      // More specific error message
      const errorMessage =
        error.message ||
        error.response?.data.message ||
        "Error processing checkout. Please try again.";

      showAlert(errorMessage);
    }
  };

  return (
    <div className={styles.container}>
      <Header2 />
      <div className={styles.pageLayout}>
        <Sidebar />
        <div className={styles.mainContent}>
          <h2 className={styles.title}>Shopping Cart</h2>
          {isLoading ? (
            <div className={styles.loadingContainer}>
              <div className={styles.loadingSpinner}></div>
              <div className={styles.loadingTextContainer}>
                <span className={styles.loadingText}>Loading cart items</span>
                <span className={styles.loadingDots}>
                  <span>.</span>
                  <span>.</span>
                  <span>.</span>
                </span>
              </div>
            </div>
          ) : !cartItems?.items || cartItems.items.length === 0 ? (
            <div className={styles.emptyCart}>
              <p className={styles.emptyMessage}>Your cart is empty</p>
              <Button
                className={styles.returnButton}
                onClick={() => navigate("/plans")}
              >
                Return to Plans
              </Button>
            </div>
          ) : (
            <>
              <div className={styles.cartItems}>
                {cartItems.items
                  .filter((item) => item.price > 0 && item.quantity > 0)
                  .map((item) => (
                    <div key={item.item_id} className={styles.cartItem}>
                      <div className={styles.itemName}>{item.item_name}</div>

                      <div className={styles.quantityControls}>
                        <button
                          className={styles.quantityButton}
                          onClick={() =>
                            updateQuantity(item.item_id, "decrease")
                          }
                          disabled={item.quantity <= 1}
                        >
                          <span className={styles.buttonSymbol}>−</span>
                        </button>
                        <span className={styles.quantity}>{item.quantity}</span>
                        <button
                          className={styles.quantityButton}
                          onClick={() =>
                            updateQuantity(item.item_id, "increase")
                          }
                        >
                          <span className={styles.buttonSymbol}>+</span>
                        </button>
                      </div>

                      <div className={styles.priceContainer}>
                        <p className={styles.itemPrice}>
                          ${(item.price * item.quantity).toFixed(2)}
                        </p>
                        <span className={styles.pricePerItem}>
                          ${item.price.toFixed(2)} each
                        </span>
                      </div>

                      <Button
                        className={styles.removeButton}
                        onClick={() => handleRemoveItem(item.item_id)}
                      >
                        Remove
                      </Button>
                    </div>
                  ))}
              </div>
              {/* Only show cart summary if there are valid items */}
              {cartItems.items.some(
                (item) => item.price > 0 && item.quantity > 0
              ) && (
                <div className={styles.cartSummary}>
                  <div className={styles.summaryDetails}>
                    <div className={styles.summaryRow}>
                      <span>Total Items:</span>
                      <span>{cartItems.total_items}</span>
                    </div>
                    <div className={styles.summaryRow}>
                      <span>Total:</span>
                      <span className={styles.totalAmount}>
                        ${cartItems.total_price}
                      </span>
                    </div>
                  </div>
                  <div className={styles.buttonGroup}>
                    <Button
                      className={`${styles.returnButton} ${styles.secondaryButton}`}
                      onClick={() => navigate("/plans")}
                    >
                      Back to Plans
                    </Button>
                    <Button
                      className={`${styles.checkoutButton} ${styles.primaryButton}`}
                      onClick={handleCheckout}
                    >
                      Proceed to Checkout
                    </Button>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default Cart;
