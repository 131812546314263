import React, { useState, useRef, useEffect, useContext } from "react";
import styles from "./PersonalInfo.module.css";
// import logo from "../../assets/images/logo.svg";
import { useNavigate } from "react-router-dom";
// import config from "../../config.js";
import Sidebar from "../common/Sidebar.js";
import Button from "../common/Button";
import {
  protectedApi,
  getCsrfToken,
  resetApplicationIdPromise,
  handleAuthError,
} from "../../services/api.js";
import Stepper from "../common/ProgressBar.js";
import Input from "../common/Input.js";
import Select from "../common/SelectDropdown.js";
import Header2 from "../common/Header2.js";
import {
  getCountryCodeFromNumber,
  getPhoneNumberWithoutCountryCode,
} from "../../services/usePhoneNumber.js";
import { useAlert } from "../common/AlertContext.js";
import { useLoading } from "../../components/common/LoadingContext";
import { ProgressBarContext } from "./ProgressBar.js";
const COUNTRY_CODES = [
  { code: "+1", country: "USA/Canada", iso: "US" },
  { code: "+7", country: "Russia/Kazakhstan", iso: "RU" },
  { code: "+20", country: "Egypt", iso: "EG" },
  { code: "+27", country: "South Africa", iso: "ZA" },
  { code: "+30", country: "Greece", iso: "GR" },
  { code: "+31", country: "Netherlands", iso: "NL" },
  { code: "+32", country: "Belgium", iso: "BE" },
  { code: "+33", country: "France", iso: "FR" },
  { code: "+34", country: "Spain", iso: "ES" },
  { code: "+36", country: "Hungary", iso: "HU" },
  { code: "+39", country: "Italy", iso: "IT" },
  { code: "+40", country: "Romania", iso: "RO" },
  { code: "+41", country: "Switzerland", iso: "CH" },
  { code: "+43", country: "Austria", iso: "AT" },
  { code: "+44", country: "UK", iso: "GB" },
  { code: "+45", country: "Denmark", iso: "DK" },
  { code: "+46", country: "Sweden", iso: "SE" },
  { code: "+47", country: "Norway", iso: "NO" },
  { code: "+48", country: "Poland", iso: "PL" },
  { code: "+49", country: "Germany", iso: "DE" },
  { code: "+51", country: "Peru", iso: "PE" },
  { code: "+52", country: "Mexico", iso: "MX" },
  { code: "+53", country: "Cuba", iso: "CU" },
  { code: "+54", country: "Argentina", iso: "AR" },
  { code: "+55", country: "Brazil", iso: "BR" },
  { code: "+56", country: "Chile", iso: "CL" },
  { code: "+57", country: "Colombia", iso: "CO" },
  { code: "+58", country: "Venezuela", iso: "VE" },
  { code: "+60", country: "Malaysia", iso: "MY" },
  { code: "+61", country: "Australia", iso: "AU" },
  { code: "+62", country: "Indonesia", iso: "ID" },
  { code: "+63", country: "Philippines", iso: "PH" },
  { code: "+64", country: "New Zealand", iso: "NZ" },
  { code: "+65", country: "Singapore", iso: "SG" },
  { code: "+66", country: "Thailand", iso: "TH" },
  { code: "+81", country: "Japan", iso: "JP" },
  { code: "+82", country: "South Korea", iso: "KR" },
  { code: "+84", country: "Vietnam", iso: "VN" },
  { code: "+86", country: "China", iso: "CN" },
  { code: "+90", country: "Turkey", iso: "TR" },
  { code: "+91", country: "India", iso: "IN" },
  { code: "+92", country: "Pakistan", iso: "PK" },
  { code: "+93", country: "Afghanistan", iso: "AF" },
  { code: "+94", country: "Sri Lanka", iso: "LK" },
  { code: "+95", country: "Myanmar", iso: "MM" },
  { code: "+98", country: "Iran", iso: "IR" },
  { code: "+1242", country: "Bahamas", iso: "BS" },
  { code: "+1246", country: "Barbados", iso: "BB" },
  { code: "+1264", country: "Anguilla", iso: "AI" },
  { code: "+1268", country: "Antigua and Barbuda", iso: "AG" },
  { code: "+1340", country: "United States Virgin Islands", iso: "VI" },
  { code: "+966", country: "Saudi Arabia", iso: "SA" },
  { code: "+998", country: "Uzbekistan", iso: "UZ" },
];

const CustomSelect = ({ value, onChange, options }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleSelect = (code) => {
    onChange({ target: { name: "countryCode", value: code } });
    setIsOpen(false);
  };

  const selectedCountry = options.find((c) => c.code === value);
  return (
    <div className={styles.customSelect} ref={dropdownRef}>
      <div className={styles.selectTrigger} onClick={() => setIsOpen(!isOpen)}>
        <img
          src={`https://flagsapi.com/${selectedCountry?.iso}/flat/48.png`}
          alt="Selected country flag"
          className={styles.flagIcon}
        />
        <span>{value}</span>
        <span className={styles.arrow}>▼</span>
      </div>
      {isOpen && (
        <div className={styles.optionsList}>
          {options.map((country) => (
            <div
              key={country.code}
              className={styles.option}
              onClick={() => handleSelect(country.code)}
            >
              <img
                src={`https://flagsapi.com/${country.iso}/flat/24.png`}
                alt={country.country}
                className={styles.flagIcon}
              />
              <span>{country.code}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

function PersonalInfo() {
  const navigate = useNavigate();
  const { handleComplete } = useContext(ProgressBarContext);
  const [formData, setFormData] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    email: "",
    countryCode: "+1",
    phoneNumber: "",
    gender: "",
    pronoun: "",
    resume: null,
  });
  const { showAlert } = useAlert();

  const [errors, setErrors] = useState({});
  const [resumeFileName, setResumeFileName] = useState("");
  const [showInitialDialog, setShowInitialDialog] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { showLoading, hideLoading } = useLoading();
  useEffect(() => {
    const fetchExistingApplication = async () => {
      try {
        showLoading("Fetching application...");
        resetApplicationIdPromise();
        const response = await protectedApi.get("/api/application/");
        console.log("GET application response:", response.data);

        if (!response?.data?.results) {
          console.error("Invalid response format:", response);
          return;
        }

        const applications = response.data.results;
        if (applications.length === 0) {
          console.warn("No applications found");
          const isInitialFiling = localStorage.getItem("isInitialFilingUser");
          const isNewRFE = localStorage.getItem("isNewRFEUser");
          if (!isInitialFiling && !isNewRFE) {
            setShowInitialDialog(true);
          }
          return;
        }

        const currentApplication = applications[applications.length - 1];
        console.log("Current application:", currentApplication);

        if (currentApplication.id) {
          console.log(
            "Saving application ID to localStorage:",
            currentApplication.id
          );
          localStorage.setItem(
            "applicationId",
            currentApplication.id.toString()
          );
        } else {
          console.error("Application has no ID:", currentApplication);
        }

        setFormData({
          firstName: currentApplication.first_name ?? "",
          middleName: currentApplication.middle_name ?? "",
          lastName: currentApplication.last_name ?? "",
          email: currentApplication.email ?? "",
          countryCode: currentApplication.phone_number
            ? getCountryCodeFromNumber(currentApplication.phone_number)
            : "+1",
          phoneNumber: currentApplication.phone_number
            ? getPhoneNumberWithoutCountryCode(currentApplication.phone_number)
            : "",
          gender: currentApplication.gender ?? "",
          pronoun: currentApplication.pronoun ?? "",
          resume: null,
        });

        if (currentApplication.resume) {
          const filename = currentApplication.resume__filename__;
          if (filename) {
            setResumeFileName(filename);
          }
        }
      } catch (error) {
        console.error("Error fetching application:", error);
        if (handleAuthError(error, navigate)) return;
        showAlert("Error fetching application");
      } finally {
        hideLoading();
      }
    };
    fetchExistingApplication();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFormSubmit = async (e, action = "save") => {
    e.preventDefault();
    e.stopPropagation();
    console.log("Form submission started");
    setIsSubmitting(true);

    try {
      const isValid = validateForm();
      if (!isValid) {
        setIsSubmitting(false);
        showAlert("Please fill in all required fields correctly");
        return;
      }

      console.log("Form is valid, preparing to send data");

      const formDataToSend = new FormData();
      const phoneNumber = formData.phoneNumber?.replace(/\D/g, "");
      const fullPhoneNumber =
        formData.countryCode && phoneNumber
          ? `${formData.countryCode}${phoneNumber}`
          : "";

      const requiredFields = {
        first_name: formData.firstName,
        last_name: formData.lastName,
        email: formData.email,
        phone_number: fullPhoneNumber,
        gender: formData.gender,
        pronoun: formData.pronoun,
      };

      for (const [key, value] of Object.entries(requiredFields)) {
        if (!value) {
          throw new Error(`Missing required field: ${key}`);
        }
        formDataToSend.append(key, value);
      }

      if (formData.middleName)
        formDataToSend.append("middle_name", formData.middleName);
      if (formData.resume) formDataToSend.append("resume", formData.resume);

      const existingApplicationId = localStorage.getItem("applicationId");
      const csrfToken = await getCsrfToken();
      console.log("CSRF Token:", csrfToken);
      let response;

      if (existingApplicationId) {
        console.log("Request configuration:", {
          url: `/api/application/${existingApplicationId}/`,
          headers: {
            "Content-Type": "multipart/form-data",
            "X-Application-ID": existingApplicationId,
          },
          csrfToken: getCsrfToken(),
          cookies: document.cookie,
        });

        response = await protectedApi.put(
          `/api/application/${existingApplicationId}/`,
          formDataToSend,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              "X-Application-Id": existingApplicationId,
            },
            withCredentials: true,
          }
        );
      } else {
        response = await protectedApi.post(
          "/api/application/",
          formDataToSend,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            withCredentials: true,
          }
        );
      }

      if (response.status === 200 || response.status === 201) {
        if (Array.isArray(response.data) && response.data.length > 0) {
          localStorage.setItem("applicationId", response.data[0].id);
        } else if (response.data && response.data.id) {
          localStorage.setItem("applicationId", response.data.id);
        }

        if (action === "continue") {
          handleComplete("personal-info");
          navigate("/education-info");
          return;
        } else {
          handleComplete("personal-info");
          showAlert("Profile saved successfully!");
        }
        setErrors({});
      }
    } catch (error) {
      console.error("Form submission error:", error);
      console.error("Form submission failed:", {
        message: error.message,
        response: error.response?.data,
        status: error.response?.status,
      });
      if (
        error.response?.status === 403 &&
        (error.response?.data?.detail?.includes("Authentication") ||
          error.response?.data?.detail?.includes("CSRF"))
      ) {
        handleAuthError(error, navigate);
        return;
      }
      let errorMessage = "An error occurred while saving your profile";
      if (error.message) {
        errorMessage = error.message;
      } else if (error.response?.data) {
        if (typeof error.response.data === "string") {
          errorMessage = error.response.data;
        } else if (typeof error.response.data === "object") {
          const details = Object.entries(error.response.data)
            .map(
              ([key, value]) =>
                `${key}: ${Array.isArray(value) ? value.join(", ") : value}`
            )
            .join("\n");
          errorMessage = details || errorMessage;
        }
      }
      showAlert(errorMessage);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleSave = (e) => {
    e.preventDefault();
    if (isSubmitting) return;
    handleFormSubmit(e, "save");
  };

  const handleContinue = (e) => {
    e.preventDefault();
    if (isSubmitting) return;
    handleFormSubmit(e, "continue");
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.type === "application/pdf") {
        setFormData((prev) => ({
          ...prev,
          resume: file,
        }));
        setResumeFileName(file.name);
      } else {
        setErrors((prev) => ({
          ...prev,
          resume: "Please upload a PDF file",
        }));
        e.target.value = null; // Reset file input
      }
    }
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.firstName) newErrors.firstName = "First name is required";
    if (!formData.lastName) newErrors.lastName = "Last name is required";
    if (!formData.email) {
      newErrors.email = "Email is required";
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      newErrors.email = "Invalid email format";
    }
    if (!formData.phoneNumber) {
      newErrors.phoneNumber = "Phone Number is required";
    } else if (!/^\d+$/.test(formData.phoneNumber)) {
      newErrors.phoneNumber = "Phone number must contain only numbers";
    }
    if (!formData.gender) newErrors.gender = "Gender is required";
    if (!formData.pronoun) newErrors.pronoun = "Prefix is required";
    if (!formData.resume && !resumeFileName) {
      newErrors.resume = "Resume is required. Please upload a PDF file.";
    } else if (formData.resume && formData.resume.type !== "application/pdf") {
      newErrors.resume = "Please upload a PDF file";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleCancel = () => {
    navigate("/"); // Redirect to personal info on cancel
  };

  const phoneNumberSection = (
    <div className={styles.phoneNumberAmount}>
      <div className={styles.bottomParent}>
        <div className={styles.phoneInputGroup}>
          <CustomSelect
            value={formData.countryCode}
            onChange={handleChange}
            options={COUNTRY_CODES}
          />
          <input
            type="tel"
            name="phoneNumber"
            placeholder="Phone Number"
            value={formData.phoneNumber}
            onChange={handleChange}
            className={styles.phoneInput}
          />
        </div>
        {errors.phoneNumber && (
          <div className={styles.errorText}>{errors.phoneNumber}</div>
        )}
      </div>
    </div>
  );

  const resumeUploadSection = (
    <div className={styles.fileUploadContainer}>
      <input
        type="file"
        accept=".pdf"
        onChange={handleFileChange}
        className={styles.fileInput}
        id="resumeUpload"
        style={{ display: "none" }}
      />
      <label htmlFor="resumeUpload" className={styles.fileInputLabel}>
        {resumeFileName || "Upload Resume (PDF)*"}
      </label>
      {(formData.resume || resumeFileName) && (
        <div className={styles.fileName}>
          Selected file: {resumeFileName}
          <button
            type="button"
            className={styles.removeFile}
            onClick={() => {
              setFormData((prev) => ({ ...prev, resume: null }));
              setResumeFileName("");
              setErrors((prev) => ({
                ...prev,
                resume: "Resume is required. Please upload a PDF file.",
              }));
            }}
          >
            ✕
          </button>
        </div>
      )}
      {errors.resume && <div className={styles.errorText}>{errors.resume}</div>}
    </div>
  );

  const InitialDialog = ({ onClose }) => {
    const handleInitialFiling = () => {
      // Todo: could add a api call to store the user status
      localStorage.setItem("isInitialFilingUser", "true");
      onClose();
    };

    const handleRFE = () => {
      localStorage.setItem("isNewRFEUser", "true");
      onClose();
    };
    // Prevent closing by clicking outside
    const handleDialogClick = (e) => {
      e.stopPropagation();
    };

    return (
      <div className={styles.dialogOverlay} onClick={handleDialogClick}>
        <div className={styles.dialogContent}>
          <h2>Welcome!</h2>
          <p>Please select your filing type:</p>
          <div className={styles.dialogButtons}>
            <button
              className={styles.dialogButton}
              onClick={handleInitialFiling}
            >
              Initial Filing
            </button>
            <button className={styles.dialogButton} onClick={handleRFE}>
              Request for Evidence (RFE)
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={styles.container}>
      {showInitialDialog && (
        <InitialDialog onClose={() => setShowInitialDialog(false)} />
      )}

      <Header2 />
      <div className={styles.pageLayout}>
        <Sidebar />
        <div className={styles.createProfile}>
          <div className={styles.wrapper}>
            <div className={styles.createYourProfile}>Create Your Profile</div>
            <div className={styles.formcontainer}>
              <div className={styles.formSection}>
                <div className={styles.sectionHeader}>
                  <h2>Personal Information</h2>
                  <Stepper currentStep={1} totalSteps={7} progressWidth={14} />
                </div>

                <form
                  onSubmit={(e) => e.preventDefault()}
                  className={styles.formcontainer}
                >
                  {errors.general && (
                    <div className={styles.errorText}>{errors.general}</div>
                  )}
                  <div className={styles.inputcontainer}>
                    <div className={styles.row}>
                      <div className={styles.leftcol}>
                        <div>
                          <input
                            type="text"
                            name="firstName"
                            placeholder="Your First Name"
                            value={formData.firstName}
                            onChange={handleChange}
                            className={styles.inputContent}
                          />
                          {errors.firstName && (
                            <div className={styles.errorText}>
                              {errors.firstName}
                            </div>
                          )}
                        </div>

                        <div>
                          <input
                            type="text"
                            name="lastName"
                            placeholder="Your Last Name"
                            value={formData.lastName}
                            onChange={handleChange}
                            className={styles.inputContent}
                          />
                          {errors.lastName && (
                            <div className={styles.errorText}>
                              {errors.lastName}
                            </div>
                          )}
                        </div>

                        <div>
                          <input
                            type="text"
                            name="middleName"
                            placeholder="Your Middle Name (Optional)"
                            value={formData.middleName}
                            onChange={handleChange}
                            className={styles.inputContent}
                          />
                        </div>

                        <div>
                          <input
                            type="email"
                            name="email"
                            placeholder="Your Email Address"
                            value={formData.email}
                            onChange={handleChange}
                            className={styles.inputContent1}
                          />
                          {errors.email && (
                            <div className={styles.errorText}>
                              {errors.email}
                            </div>
                          )}
                        </div>

                        <div className={styles.leftcol}>
                          {phoneNumberSection}

                          <div className={styles.dropdownContainer}>
                            <select
                              name="gender"
                              value={formData.gender}
                              onChange={handleChange}
                              className={styles.genderDropdown}
                            >
                              <option value="" disabled>
                                Gender
                              </option>
                              <option value="Male">Male</option>
                              <option value="Female">Female</option>
                              <option value="Other">Other</option>
                            </select>
                            {errors.gender && (
                              <div className={styles.errorText}>
                                {errors.gender}
                              </div>
                            )}
                          </div>

                          <div className={styles.dropdownContainer}>
                            <select
                              name="pronoun"
                              value={formData.pronoun}
                              onChange={handleChange}
                              className={styles.genderDropdown}
                            >
                              <option value="" disabled>
                                Prefix
                              </option>
                              <option value="Mr.">Mr.</option>
                              <option value="Mrs.">Mrs.</option>
                              <option value="Ms.">Ms.</option>
                              <option value="Dr.">Dr.</option>
                            </select>
                            {errors.pronoun && (
                              <div className={styles.errorText}>
                                {errors.pronoun}
                              </div>
                            )}
                          </div>

                          {resumeUploadSection}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.buttoncontainer}>
                    <Button variant="secondary" onClick={handleCancel}>
                      Cancel
                    </Button>
                    <Button variant="secondary" onClick={handleSave}>
                      Save
                    </Button>
                    <Button
                      variant="primary"
                      onClick={handleContinue}
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? "Processing..." : "Continue"}
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PersonalInfo;
