const COUNTRY_CODES = [
    { code: "+1", country: "USA/Canada", iso: "US" },
    { code: "+7", country: "Russia/Kazakhstan", iso: "RU" },
    { code: "+20", country: "Egypt", iso: "EG" },
    { code: "+27", country: "South Africa", iso: "ZA" },
    { code: "+30", country: "Greece", iso: "GR" },
    { code: "+31", country: "Netherlands", iso: "NL" },
    { code: "+32", country: "Belgium", iso: "BE" },
    { code: "+33", country: "France", iso: "FR" },
    { code: "+34", country: "Spain", iso: "ES" },
    { code: "+36", country: "Hungary", iso: "HU" },
    { code: "+39", country: "Italy", iso: "IT" },
    { code: "+40", country: "Romania", iso: "RO" },
    { code: "+41", country: "Switzerland", iso: "CH" },
    { code: "+43", country: "Austria", iso: "AT" },
    { code: "+44", country: "UK", iso: "GB" },
    { code: "+45", country: "Denmark", iso: "DK" },
    { code: "+46", country: "Sweden", iso: "SE" },
    { code: "+47", country: "Norway", iso: "NO" },
    { code: "+48", country: "Poland", iso: "PL" },
    { code: "+49", country: "Germany", iso: "DE" },
    { code: "+51", country: "Peru", iso: "PE" },
    { code: "+52", country: "Mexico", iso: "MX" },
    { code: "+53", country: "Cuba", iso: "CU" },
    { code: "+54", country: "Argentina", iso: "AR" },
    { code: "+55", country: "Brazil", iso: "BR" },
    { code: "+56", country: "Chile", iso: "CL" },
    { code: "+57", country: "Colombia", iso: "CO" },
    { code: "+58", country: "Venezuela", iso: "VE" },
    { code: "+60", country: "Malaysia", iso: "MY" },
    { code: "+61", country: "Australia", iso: "AU" },
    { code: "+62", country: "Indonesia", iso: "ID" },
    { code: "+63", country: "Philippines", iso: "PH" },
    { code: "+64", country: "New Zealand", iso: "NZ" },
    { code: "+65", country: "Singapore", iso: "SG" },
    { code: "+66", country: "Thailand", iso: "TH" },
    { code: "+81", country: "Japan", iso: "JP" },
    { code: "+82", country: "South Korea", iso: "KR" },
    { code: "+84", country: "Vietnam", iso: "VN" },
    { code: "+86", country: "China", iso: "CN" },
    { code: "+90", country: "Turkey", iso: "TR" },
    { code: "+91", country: "India", iso: "IN" },
    { code: "+92", country: "Pakistan", iso: "PK" },
    { code: "+93", country: "Afghanistan", iso: "AF" },
    { code: "+94", country: "Sri Lanka", iso: "LK" },
    { code: "+95", country: "Myanmar", iso: "MM" },
    { code: "+98", country: "Iran", iso: "IR" },
    { code: "+1242", country: "Bahamas", iso: "BS" },
    { code: "+1246", country: "Barbados", iso: "BB" },
    { code: "+1264", country: "Anguilla", iso: "AI" },
    { code: "+1268", country: "Antigua and Barbuda", iso: "AG" },
    { code: "+1340", country: "United States Virgin Islands", iso: "VI" },
    { code: "+966", country: "Saudi Arabia", iso: "SA" },
    { code: "+998", country: "Uzbekistan", iso: "UZ" },
  ];

// Sort country codes by length (longest first) to ensure correct matching
const sortedCountryCodes = COUNTRY_CODES.sort((a, b) => b.code.length - a.code.length);

/**
 * Gets the country code from a phone number
 * @param {string} phoneNumber - The full phone number to parse
 * @returns {string} The matched country code or "+1" as default
 */

export const getPhoneNumberWithoutCountryCode = (phoneNumber) => {
    return phoneNumber.substring(getCountryCodeFromNumber(phoneNumber).length);
}

export const getCountryCodeFromNumber = (phoneNumber) => {
    if (!phoneNumber) return "+1"; // default to "+1" if no phone number provided
    
    const match = sortedCountryCodes.find(country => 
        phoneNumber.startsWith(country.code)
    );
    
    return match ? match.code : "+1";
};

/**
 * Gets all country codes and their details
 * @returns {Array} Array of country code objects
 */
export const getAllCountryCodes = () => COUNTRY_CODES;

/**
 * Gets country details by country code
 * @param {string} code - The country code to look up
 * @returns {Object|null} Country details object or null if not found
 */
export const getCountryDetailsByCode = (code) => {
    return COUNTRY_CODES.find(country => country.code === code) || null;
};

