// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.VerificationPage_verificationContainer__EKaQg {
  max-width: 600px;
  margin: 80px auto;
  padding: 40px;
  text-align: center;
  border-radius: 20px;
  background: white;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.08);
}

.VerificationPage_iconContainer__lL\\+98 {
  margin-bottom: 25px;
}

.VerificationPage_iconContainer__lL\\+98 i {
  font-size: 48px;
  color: #4CAF50;
}

.VerificationPage_heading__TXIB- {
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 20px;
  color: #2c3e50;
}

.VerificationPage_divider__27hDq {
  height: 3px;
  width: 60px;
  background: linear-gradient(to right, #4CAF50, #81C784);
  margin: 20px auto;
  border-radius: 2px;
}

.VerificationPage_message__RPM3y {
  font-size: 16px;
  line-height: 1.6;
  color: #546e7a;
  margin-bottom: 25px;
  padding: 0 20px;
}

.VerificationPage_note__Um76h {
  font-size: 15px;
  color: #78909c;
  background: #f5f7f9;
  padding: 15px;
  border-radius: 10px;
  margin-top: 20px;
}

.VerificationPage_resendLink__50kGL {
  color: #4CAF50;
  text-decoration: none;
  font-weight: 500;
  transition: color 0.2s ease;
}

.VerificationPage_resendLink__50kGL:hover {
  color: #388E3C;
  text-decoration: underline;
}
  `, "",{"version":3,"sources":["webpack://./src/components/VerificationPage.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,iBAAiB;EACjB,aAAa;EACb,kBAAkB;EAClB,mBAAmB;EACnB,iBAAiB;EACjB,2CAA2C;AAC7C;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,cAAc;AAChB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,mBAAmB;EACnB,cAAc;AAChB;;AAEA;EACE,WAAW;EACX,WAAW;EACX,uDAAuD;EACvD,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,cAAc;EACd,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,eAAe;EACf,cAAc;EACd,mBAAmB;EACnB,aAAa;EACb,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,qBAAqB;EACrB,gBAAgB;EAChB,2BAA2B;AAC7B;;AAEA;EACE,cAAc;EACd,0BAA0B;AAC5B","sourcesContent":[".verificationContainer {\n  max-width: 600px;\n  margin: 80px auto;\n  padding: 40px;\n  text-align: center;\n  border-radius: 20px;\n  background: white;\n  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.08);\n}\n\n.iconContainer {\n  margin-bottom: 25px;\n}\n\n.iconContainer i {\n  font-size: 48px;\n  color: #4CAF50;\n}\n\n.heading {\n  font-size: 32px;\n  font-weight: 600;\n  margin-bottom: 20px;\n  color: #2c3e50;\n}\n\n.divider {\n  height: 3px;\n  width: 60px;\n  background: linear-gradient(to right, #4CAF50, #81C784);\n  margin: 20px auto;\n  border-radius: 2px;\n}\n\n.message {\n  font-size: 16px;\n  line-height: 1.6;\n  color: #546e7a;\n  margin-bottom: 25px;\n  padding: 0 20px;\n}\n\n.note {\n  font-size: 15px;\n  color: #78909c;\n  background: #f5f7f9;\n  padding: 15px;\n  border-radius: 10px;\n  margin-top: 20px;\n}\n\n.resendLink {\n  color: #4CAF50;\n  text-decoration: none;\n  font-weight: 500;\n  transition: color 0.2s ease;\n}\n\n.resendLink:hover {\n  color: #388E3C;\n  text-decoration: underline;\n}\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"verificationContainer": `VerificationPage_verificationContainer__EKaQg`,
	"iconContainer": `VerificationPage_iconContainer__lL+98`,
	"heading": `VerificationPage_heading__TXIB-`,
	"divider": `VerificationPage_divider__27hDq`,
	"message": `VerificationPage_message__RPM3y`,
	"note": `VerificationPage_note__Um76h`,
	"resendLink": `VerificationPage_resendLink__50kGL`
};
export default ___CSS_LOADER_EXPORT___;
